

function newAkademieInvoice(props) {
    const {
      handleAkademieInvoicePush,
      handleAkademieInvoiceUpdate,
      akademieInvoiceData,
      handleAkademieInvoiceChange,
      contacts,
      handleNavpoint,
      countries,
      complete,
      dontChange
    } = props

    return(
      <>
        {akademieInvoiceData.id === null ? <h1>Akademie: Neue Rechnung</h1> : <h1>{akademieInvoiceData.invoiceNumber + ": " + akademieInvoiceData.name} </h1>}
        <hr/>
        <br/>
        <br/>
        {dontChange === false ? <>
        <div className='Panel'>
          {complete === false ? 
          <div style={{width: "100%", backgroundColor: "red", color: "white", textAlign: "center", height: "30px", justifyContent: "center"}}>
            <a>Bitte Pflichtfelder bearbeiten</a>
          </div>
          :
          <></>
          }
          <div>
            {akademieInvoiceData.id === null ? <button onClick={e => handleAkademieInvoicePush()}>Speichern</button> : <button onClick={() => handleAkademieInvoiceUpdate()}>Update</button>} 
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('AkademieInvoicePreview')}>Vorschau</button>
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('AkademieInvoices')}>Abbrechen</button>
          </div>
        </div>
      <div className="Panel">
        <div class="row">
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="contacts" className="smallSpace">Kontakt: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.nameSex === "" ? <lable style={{color: "red"}} htmlFor="nameSex" className="smallSpace">Geschlecht: </lable> : <lable htmlFor="nameSex" className="smallSpace">Geschlecht: </lable>}
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="firstName" className="smallSpace">Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.name === "" && akademieInvoiceData.businessName !== '' && akademieInvoiceData.businessName !== null ? <lable htmlFor="name" className="smallSpace" style={{color: "red"}}>Name: </lable> : <lable htmlFor="lastname" className="smallSpace">Name: </lable>}
            </div>
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.businessName === "" && akademieInvoiceData.name !== '' && akademieInvoiceData.name !== null ? <lable htmlFor="name" className="smallSpace" style={{color: "red"}}>Firmenname: </lable> : <lable htmlFor="lastname" className="smallSpace">Firmenname: </lable>}
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
            <div className="upperSpace">
                    <select disabled={akademieInvoiceData.name === "" || akademieInvoiceData.date === " " || akademieInvoiceData.type === "" || akademieInvoiceData.paidText === "" || akademieInvoiceData.invoiceText === "" || akademieInvoiceData.invoiceText === 'Sehr geehrter Herr ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' || akademieInvoiceData.invoiceText === 'Sehr geehrte Frau ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' ? false : true} id="contacts" name="contacts" className="space" style={{width: '80%'}} onChange={handleAkademieInvoiceChange}>
                      <option value="" disabled selected>Kontakt auswählen</option>
                      {contacts.map(c => (
                        <>
                          <option value={c.id} label={c.businessName !== '' && c.businessName !== null && c.businessName !== undefined && c.businessName !== 'null' && c.businessName !== 'undefined' ? c.businessName + ' | ' + c.lastName : c.lastName + ', ' + c.firstName}/>
                        </>
                      ))}
                    </select>
                </div>
              <div className="upperSpace">
                  <select id="nameSex" name="nameSex" className="space" style={{width: '80%'}} onChange={handleAkademieInvoiceChange} value={akademieInvoiceData.nameSex}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="female" label='Frau'/>
                    <option value="male" label='Herr'/>
                    <option value="firma" label='Firma'/>
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="firstName"
                    className="space"
                    name="firstName"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.firstName}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="name"
                    className="space"
                    name="name"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.name}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="businessName"
                    className="space"
                    name="businessName"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.businessName}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
          </div>
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="street" className="smallSpace">Straße: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="streetNumber" className="smallSpace">Straßennummer: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="zip" className="smallSpace">Postleitzahl: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="city" className="smallSpace">Stadt: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="country" className="smallSpace">Land: </lable>
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
          <div className="upperSpace">
                  <input
                    id="street"
                    className="space"
                    name="street"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.street}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="streetNumber"
                    className="space"
                    name="streetNumber"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.streetNumber}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="zip"
                    className="space"
                    name="zip"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.zip}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="city"
                    className="space"
                    name="city"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieInvoiceData.city}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
              <select id="country" name="country" className="upperSpace" style={{width: '80%'}} onChange={handleAkademieInvoiceChange} value={akademieInvoiceData.country}>
                  <option value="" disabled selected>Bitte wählen</option>
                  <option value="Deutschland">Deutschland</option>
                  {countries.map(c => (
                    <>
                      <option value={c.text}>{c.text}</option>
                    </>
                  ))}
                </select>
          </div>
        </div>
      </div>
      <hr/>
      {((akademieInvoiceData.name !== '' || (akademieInvoiceData.businessName !== '' && akademieInvoiceData.businessName !== null)) && akademieInvoiceData.nameSex !== '') || akademieInvoiceData.id !== null ? 
      <>
      <div className="Panel">
        <div class="row">
          <div class="column">
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.date === "" ? <lable htmlFor="date" className="smallSpace" style={{color: "red"}}>Briefdatum: </lable> : <lable htmlFor="date" className="smallSpace">Datum: </lable>}
            </div>
          </div>
          <div class="column" style={{style: '400px'}}>
            <div className="upperSpace">
                  <input
                    id="date"
                    className="space"
                    name="date"
                    type="date"
                    style={{width: '320px'}}
                    value={akademieInvoiceData.date}
                    onChange={handleAkademieInvoiceChange}
                  />
              </div>
          </div>
          <div class="column">
          {akademieInvoiceData.id === null ?
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.paidText === "" ? <lable htmlFor="paidText" className="smallSpace" style={{color: "red"}}>Bezahlt: </lable> : <lable htmlFor="paidText" className="smallSpace">Bezahlt: </lable>}
            </div>
            : <></>}
          </div>
          <div class="column" style={{style: '400px'}}>
            {akademieInvoiceData.id === null ?
            <div className="upperSpace">
              <select id="paidText" name="paidText" className="space" style={{width: '320px'}} onChange={handleAkademieInvoiceChange} value={akademieInvoiceData.paidText}>
                <option value="" disabled >Bitte wählen</option>
                <option value="Betrag dankend erhalten.">Betrag dankend erhalten.</option>
                <option value="Sofort zahlbar ohne Abzug.">Sofort zahlbar ohne Abzug.</option>
              </select>
            </div>
            : <></>
            }
          </div>
        </div>
      </div>
      <hr/></> : <></>}
      {((akademieInvoiceData.name !== '' || (akademieInvoiceData.businessName !== '' && akademieInvoiceData.businessName !== null)) && akademieInvoiceData.nameSex !== '' && akademieInvoiceData.date !== '' && akademieInvoiceData.paidText !== '') || akademieInvoiceData.id !== null ?
        <div className='Panel'>
        <div className='row'>
          <div className='column'>
            <div className="upperSpaceLable">
              {complete === false && akademieInvoiceData.invoiceNumber === "" ? <lable htmlFor="invoiceNumber" className="smallSpace" style={{color: "red"}}>Rechnungsnummer: </lable> : <lable htmlFor="invoiceNumber" className="smallSpace">Rechnungsnummer: </lable>}
            </div>
            <div className="upperSpaceLable" >{/*style={{marginTop: '10px'}}*/}
              {complete === false && akademieInvoiceData.invoiceText === "" ? <lable htmlFor="invoiceText" className="smallSpace" style={{color: "red"}}>Rechnungstext: </lable> : <lable htmlFor="invoiceText" className="smallSpace">Rechnungstext: </lable>}
              {/*<button onClick={e => addDiagnoseButton()}>+</button>*/}
            </div>
          </div>
          <div className='column' style={{width: '1110px', marginRight: '-135px'}}>
              <div className="upperSpace">
                    <a
                      id="invoiceNumber"
                      className="space"
                      name="invoiceNumber"
                      style={{width: '80%'}}
                    >{akademieInvoiceData.invoiceNumber}</a>
                </div>
                <div className="upperSpace">
                    <textarea 
                      id='invoiceText'
                      name="invoiceText" 
                      style={{
                        width: '80%', 
                        marginBottom: '64px',
                        fontFamily: 'Helvetica',
                        fontSize: '16px'
                        }} 
                      rows="12" 
                      cols="50" 
                      value={
                        (akademieInvoiceData.invoiceText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.nameSex === 'male' && akademieInvoiceData.name !== '') ? 'Sehr geehrter Herr ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : (akademieInvoiceData.invoiceText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.nameSex === 'female' && akademieInvoiceData.name !== '') ? 'Sehr geehrte Frau ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : (akademieInvoiceData.invoiceText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.nameSex === 'firma' && akademieInvoiceData.businessName !== '') ? 'Sehr geehrte Damen und Herren,' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : akademieInvoiceData.invoiceText
                      }
                      onChange={handleAkademieInvoiceChange}
                    />
                </div>
          </div>
        </div>
      </div> : <></>}
      </> : <>
        <a>Bei nachträglichen Änderungen werden alle Daten zurückgesetzt</a>
        <button name='change' onClick={handleAkademieInvoiceChange}>Daten löschen</button>
        <button name='dontChange' onClick={handleAkademieInvoiceChange}>Abbrechen</button>
      </>}

      </>
    )
}

export default newAkademieInvoice;









