import "./Invoices.css"
import logo from "../../Logo.png"

function Invoices(props) {
    const {
        handleInvoiceDetails,
        invoices,
        handleNavpoint,
        isLoading,
        loadText
    } = props
    return(
        <>
        <h1>Institut Rechnungen</h1>
        <hr/>
        {isLoading ? 
        <div style={{textAlign: 'center'}}>
            <img src={logo} className="Wait-logo" alt="logo" />
            <a className="Wait-text">Bitte Warten</a>
            <p className="Wait-text">{loadText}</p>
        </div>
        :
        <>
            <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('NewInvoice')}}>Neue Rechnung</button>
            <table>
                <tr>
                    <th>R.Nr.</th>
                    <th>Datum</th>
                    <th>Name</th>
                    <th>Vorname</th>
                    <th>Rechungsbetrag</th>
                    <th>Status</th>
                </tr>
                <br></br>
                {invoices.length > 0 && (
                    invoices.map(invoice =>(
                    <>
                        <tr className="clickabale" onClick={() => handleInvoiceDetails(invoice)}>
                            <th>{invoice.invoiceNumber}</th>
                            <th>{invoice.date}</th>
                            <th>{invoice.name}</th>
                            <th>{invoice.firstName}</th>
                            <th>{invoice.sum + ' €'}</th>
                            <th>{invoice.paidText === "Betrag dankend erhalten." ? "Bezahlt" : invoice.paidText === "Sofort zahlbar ohne Abzug." ? "Nicht Bezahlt" : "X"}</th>
                        </tr>
                    </>
                    ))
                )}
            </table>
        </>}
        </>
    )
}

export default Invoices;