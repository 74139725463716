import "./Links.css"

import onePassword from "../../Logos/1Password.png"
import confluence from "../../Logos/Confluence.png"
import jira from "../../Logos/Jira.png"
import kalender from "../../Logos/Kalender.png"
import naspa from "../../Logos/Sparkasse.png"
import amazon from "../../Logos/Amazon.png"
import ankorstore from "../../Logos/Ankorstore.png"
import bodo from "../../Logos/Bodo.png"
import datev from "../../Logos/Datev.png"
import dpd from "../../Logos/dpd.png"
import faire from "../../Logos/Faire.png"
import groupon from "../../Logos/Groupon.png"
import printus from "../../Logos/Printus.png"
import sumup from "../../Logos/SumUp.png"

function Links(props) {
    return(
        <>
        <div className="Nav-Links">
            <div class="column">
            <h3>1Password</h3>
            <a href="https://simlifeinstitutsimonamichel.1password.com/signin?landing-page=%2Fvaults%2Fw3tnax7jy5f5uys5jyl5rgudeq%2Fallitems%2Fb75jjgsuiksggaeyfwcozhgjly" target="_blank"> <img className="Nav-Img" src={onePassword} alt="1Pasword"/> </a>
            </div>
            <div className="column">
            <h3>Amazon</h3>
            <a href="https://amazon.de" target="_blank"> <img className="Nav-Img" src={amazon} alt="Amazon"/> </a>
            </div>
            <div className="column">
            <h3>Ankorstore</h3>
            <a href="https://de.ankorstore.com/account/orders" target="_blank"> <img className="Nav-Img" src={ankorstore} alt="Ankorstore"/> </a>
            </div>
            <div className="column">
            <h3>Bodo</h3>
            <a href="https://www.bsacoaching.de" target="_blank"> <img className="Nav-Img" src={bodo} alt="Bodo"/> </a>
            </div>
            <div class="column">
            <h3>Confluence</h3>
            <a href="https://simlife.atlassian.net/wiki/home" target="_blank"> <img className="Nav-Img" src={confluence} alt="Cofluence"/> </a>
            </div>
        </div>

        <div className="Nav-Links">
        <div className="column">
            <h3>Datev</h3>
            <a href="https://login.datev.de/" target="_blank"> <img className="Nav-Img" src={datev} alt="Datev"/> </a>
        </div>
        <div className="column">
            <h3>DPD</h3>
            <a href="https://business.dpd.de" target="_blank"> <img className="Nav-Img" src={dpd} alt="DPD"/> </a>
        </div>
        <div className="column">
            <h3>Faire</h3>
            <a href="https://www.faire.com/brand-portal/orders" target="_blank"> <img className="Nav-Img" src={faire} alt="Faire"/> </a>
        </div>
        <div className="column">
            <h3>Groupon</h3>
            <a href="https://www.groupon.de/merchant/center/" target="_blank"> <img className="Nav-Img" src={groupon} alt="Groupon"/> </a>
        </div>
        <div class="column">
            <h3>Jira</h3>
            <a href="https://simlife.atlassian.net/jira/core/projects/FIRST/board" target="_blank"> <img className="Nav-Img" src={jira} alt="Jira"/></a>
        </div>
        </div>

        <div className="Nav-Links">
        <div className="column">
            <h3>Kalender</h3>
            <a href="https://kalender.digital/e88a8d4ed16cc43d07af" target="_blank"> <img className="Nav-Img" src={kalender} alt="Kalender"/> </a>
        </div>
        <div class="column">
            <h3>Naspa</h3>
            <a href="https://www.naspa.de/de/home.html" target="_blank"> <img className="Nav-Img" src={naspa} alt="Naspa"/> </a>
        </div>
        <div className="column">
            <h3>Printus</h3>
            <a href="https://www.printus.de" target="_blank"> <img className="Nav-Img" src={printus} alt="Printus"/> </a>
        </div>
        <div className="column">
            <h3>SumUp</h3>
            <a href="https://app.tillersystems.com/" target="_blank"> <img className="Nav-Img" src={sumup} alt="SumUp"/> </a>
        </div>
        </div>
        </>
    )
}

export default Links;