import "./AkademieInvoices.css"
import logo from "../../Logo.png"

function AkademieInvoices(props) {
    const {
        handleAkademieInvoiceDetails,
        akademieInvoices,
        handleNavpoint,
        isLoading,
        loadText,
        checkAkademieInvoice
    } = props
    return(
        <>
        <h1>Akademie Rechnungen</h1>
        <hr/>
        {isLoading ? 
        <div style={{textAlign: 'center'}}>
            <img src={logo} className="Wait-logo" alt="logo" />
            <a className="Wait-text">Bitte Warten</a>
            <p className="Wait-text">{loadText}</p>
        </div>
        :
        <>
            <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('NewAkademieInvoice')}}>Neue Rechnung</button>
            <table>
                <tr>
                    <th>R.Nr.</th>
                    <th>Datum</th>
                    <th>Name</th>
                    <th>Vorname</th>
                    <th>Status</th>
                    <th>Check</th>
                </tr>
                <br></br>
                {akademieInvoices.length > 0 && (
                    akademieInvoices.map(akademieInvoice =>(
                    <>
                        <tr className="clickabale">
                            <th onClick={() => handleAkademieInvoiceDetails(akademieInvoice)}>{akademieInvoice.invoiceNumber}</th>
                            <th onClick={() => handleAkademieInvoiceDetails(akademieInvoice)}>{akademieInvoice.date}</th>
                            <th onClick={() => handleAkademieInvoiceDetails(akademieInvoice)}>{akademieInvoice.name === null || akademieInvoice.name === '' ? akademieInvoice.businessName : akademieInvoice.name}</th>
                            <th onClick={() => handleAkademieInvoiceDetails(akademieInvoice)}>{akademieInvoice.firstName}</th>
                            <th onClick={() => handleAkademieInvoiceDetails(akademieInvoice)}>{akademieInvoice.paidText === "Betrag dankend erhalten." ? "Bezahlt" : akademieInvoice.paidText === "Sofort zahlbar ohne Abzug." ? "Nicht Bezahlt" : "X"}</th>
                            <th>{akademieInvoice.paidText === "Betrag dankend erhalten." ? <></> : akademieInvoice.paidText === "Sofort zahlbar ohne Abzug." ? <input onClick={() => checkAkademieInvoice(akademieInvoice)} type={"checkbox"}/> : "X"}</th>
                        </tr>
                    </>
                    ))
                )}
            </table>
        </>}
        </>
    )
}

export default AkademieInvoices;