import "./NewContact.css"

function NewContact(props) {
    const {
        contactData,
        countries,
        handleContactChange,
        handleContactPush,
        handleContactUpdate,
        handleNavpoint,
        complete,
        startContact,
        typeNumberValue
    } = props
  return(
    <>
      <h1>{contactData.id === null ? "Neuer Kontakt" : contactData.firstName + " " + contactData.lastName}</h1>
      <hr/>
      <br/>
      <br/>
      { startContact === false && contactData.id === null ?
      <>
        <a>Welche Art von Kontakt möchten Sie anlegen?</a>
        <button name='type' onClick={handleContactChange} value={'Kunde'}>Kunde</button>
        <button name='type' onClick={handleContactChange} value={'Lieferant'}>Lieferant</button>
      </> :
      <div className="Panel">
        {complete === false ? 
          <div style={{width: "100%", backgroundColor: "red", color: "white", textAlign: "center", height: "30px", justifyContent: "center"}}>
            <a>Bitte Pflichtfelder bearbeiten</a>
          </div>
          :
          <></>
        }
      
        <div className="saveButton">
          {/*contactData.id === null ? <></> : <button className="upperSpace" id="contacts" name="contacts" onClick={e => handleInvoiceChange(e)}>Neue Rechnung</button>*/}
          {contactData.id === null ? <button className="upperSpace " onClick={() => handleContactPush()}>Speichern</button> : <button className="upperSpace " onClick={() => handleContactUpdate()}>Update</button>}
          <button className="upperSpace " style={{marginLeft:'20px'}} onClick={() => handleNavpoint('Book')}>Abbrechen</button>
        </div>

        <div class="row">
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="gender" className="smallSpace">Geschlecht: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="title" className="smallSpace">Title: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && contactData.lastName === "" ? <lable htmlFor="lastName" className="smallSpace" style={{color: "red"}}>Name: </lable> : <lable htmlFor="lastName" className="smallSpace">Name: </lable>}
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="firstName" className="smallSpace">Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="secondName" className="smallSpace">2. Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="businessName" className="smallSpace">Firmenname: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="birthday" className="smallSpace">Geburtstag: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && contactData.type === "" ? <lable htmlFor="type" className="smallSpace" style={{color: "red"}}>Kontaktart: </lable> : <lable htmlFor="type" className="smallSpace">Kontaktart: </lable>}
            </div>
          </div>
          <div class="column"> {/*Inputs Colum 1*/}
              <div className="upperSpace">
                  <select id="gender" name="gender" className="space" onChange={handleContactChange} value={contactData.gender}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="female">weiblich</option>
                    <option value="male">männlich</option>
                    <option value="firma">Firma</option>
                  </select>
              </div>
              <div className="upperSpace">
                  <select id="title" name="title" className="space" onChange={handleContactChange} value={contactData.title}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="m.a.">M.A.</option>
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="lastName"
                    className="space"
                    name="lastName"
                    type="text"
                    value={contactData.lastName}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="firstName"
                    className="space"
                    name="firstName"
                    type="text"
                    value={contactData.firstName}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="secondName"
                    className="space"
                    name="secondName"
                    type="text"
                    value={contactData.secondName}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="businessName"
                    className="space"
                    name="businessName"
                    type="text"
                    value={contactData.businessName}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="birthday"
                    className="space"
                    name="birthday"
                    type="date"
                    value={contactData.birthday}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <select id="type" name="type" className="space" onChange={handleContactChange} value={contactData.type}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="Kunde">Kunde</option>
                    <option value="Lieferant">Lieferant</option>
                  </select>
              </div>
          </div>
          <div class="column"> {/*Lables Row 2*/}
            <div className="upperSpaceLable">
              <lable htmlFor="addressStreet" className="smallSpace">Straße: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="addressNumber" className="smallSpace">Nr.: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="addressZip" className="smallSpace">Postleitzahl: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="addressCity" className="smallSpace">Ort: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="country" className="smallSpace">Land: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="mobile" className="smallSpace">HandyNr.: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="phone" className="smallSpace">Festnetz: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="email" className="smallSpace">Email: </lable>
            </div>
          </div>
          <div class="column"> {/*Inputs Row 2*/}
              <div className="upperSpace">
                  <input
                    id="addressStreet"
                    className="space"
                    name="addressStreet"
                    type="text"
                    value={contactData.addressStreet}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="addressNumber"
                    className="space"
                    name="addressNumber"
                    type="text"
                    value={contactData.addressNumber}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="addressZip"
                    className="space"
                    name="addressZip"
                    type="text"
                    value={contactData.addressZip}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="addressCity"
                    className="space"
                    name="addressCity"
                    type="text"
                    value={contactData.addressCity}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <select id="country" name="country" className="space" onChange={handleContactChange} value={contactData.country}>
                    <option value="Deutschland">Deutschland</option>
                    <option value=""><hr/></option>
                    {countries.map(c => (
                      <>
                        <option value={c.text}>{c.text}</option>
                      </>
                    ))}
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="mobile"
                    className="space"
                    name="mobile"
                    type="text"
                    value={contactData.mobile}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="phone"
                    className="space"
                    name="phone"
                    type="text"
                    value={contactData.phone}
                    onChange={handleContactChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="email"
                    className="space"
                    name="email"
                    type="text"
                    value={contactData.email}
                    onChange={handleContactChange}
                  />
              </div>
          </div>
          <div class="column"> {/*Lables Row 3*/}
            <div className="upperSpaceLable">
              <lable htmlFor="tags" className="smallSpace">Versicherung: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="newsletter" className="smallSpace">Newsletter: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="notes" className="smallSpace">Notizen: </lable>
            </div>
            <div className="upperSpaceLable"style={{marginTop: "110px"}} >
              <lable htmlFor="contactID" className="smallSpace">Kontakt Nr.: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && contactData.createdFrom === "" ? <lable htmlFor="createdFrom" className="smallSpace" style={{color: "red"}}>Erstellt von: </lable> : <lable htmlFor="createdFrom" className="smallSpace">Erstellt von: </lable>}
            </div>
          </div>
          <div class="column">
              <div className="upperSpace">
                  <select id="tags" name="tags" className="space" onChange={handleContactChange} value={contactData.tags}>
                    <option value="" disabled >Bitte wählen</option>
                    <option value="privat">privat</option>
                    <option value="gesetzlich">gesetzlich</option>
                  </select>
              </div>
              <div className="upperSpace">
                  <select id="newsletter" name="newsletter" className="space" onChange={handleContactChange} value={contactData.newsletter}>
                    <option value="" disabled >Bitte wählen</option>
                    <option value="Nein">Nein</option>
                    <option value="Ja">Ja</option>
                  </select>
              </div>
              <div className="upperSpace">
                    <textarea 
                      id='notes'
                      name="notes"
                      style={{resize: 'none'}}
                      rows="5" 
                      cols="30" 
                      value={contactData.notes}
                      onChange={handleContactChange}
                    />
                </div>
              <div className="upperSpace">
                  {contactData.id === null ? <a id="contactID" name="contactID" className="space">{contactData.type.substring(0,1) + "_" + typeNumberValue}</a> : <a id="contactID" name="contactID" className="space">{contactData.type}</a>}
              </div>
              <div className="upperSpace">

              </div>
              <div className="upperSpace">

              </div>
              <div className="upperSpace">
                  <select id="createdFrom" name="createdFrom" className="space" onChange={handleContactChange} value={contactData.createdFrom}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="Alexander Zgela">Alexander Zgela</option>
                    <option value="Simona Michel">Simona Michel</option>
                    <option value="Tobias Engel">Tobias Engel</option>
                    <option value="Birgit Pleyer">Birgit Pleyer</option>
                  </select>
              </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default NewContact;