import "./AkademieLetter.css"
import logo from "../../Logo.png"

function AkademieLetter(props) {
    const {
        handleAkademieLetterDetails,
        akademieLetters,
        handleNavpoint,
        isLoading,
        loadText
    } = props
    return(
        <>
        <h1>Akademie Geschäftsbriefe</h1>
        <hr/>
        {isLoading ? 
        <div style={{textAlign: 'center'}}>
            <img src={logo} className="Wait-logo" alt="logo" />
            <a className="Wait-text">Bitte Warten</a>
            <p className="Wait-text">{loadText}</p>
        </div>
        :
        <>
            <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('NewAkademieLetter')}}>Neuer Geschäftsbrief</button>
            <table>
                <tr>
                    <th>Nr.</th>
                    <th>Datum</th>
                    <th>Name</th>
                    <th>Vorname</th>
                </tr>
                <br></br>
                {akademieLetters.length > 0 && (
                    akademieLetters.map(akademieLetter =>(
                    <>
                        <tr className="clickabale" onClick={() => handleAkademieLetterDetails(akademieLetter)}>
                            <th>{akademieLetter.id}</th>
                            <th>{akademieLetter.date}</th>
                            <th>{akademieLetter.lastName}</th>
                            <th>{akademieLetter.firstName}</th>
                        </tr>
                    </>
                    ))
                )}
            </table>
        </>}
        </>
    )
}

export default AkademieLetter;