import { useState, useEffect } from "react"
import './App.css'

import Navbar from "./Pages/Navbar"
import Login from "./Pages/Login"
import MissedCalls from "./Pages/MissedCalls"
import Links from "./Pages/Links"
import Phonebook from "./Pages/Phonebook"
import NewContact from "./Pages/NewContact"
import InstitutInvoices from "./Pages/Invoices"
import ManufakturInvoices from "./Pages/ManufakturInvoices"
import AkademieInvoices from "./Pages/AkademieInvoices"
import NewInvoice from "./Pages/institutInvoice"
import NewManufakturInvoice from "./Pages/manufakturInvoice"
import NewAkademieInvoice from "./Pages/akademieInvoice"
import InstitutLetter from "./Pages/InstitutLetter"
import ManufakturLetter from "./Pages/ManufakturLetter"
import AkademieLetter from "./Pages/AkademieLetter"
import NewInstitutLetter from "./Pages/newInstitutLetter"
import NewManufakturLetter from "./Pages/newManufakturLetter"
import NewAkademieLetter from "./Pages/newAkademieLetter"
import ManufakturInvoicePreview from "./Pages/ManufakturInvoicePreview"
import AkademieInvoicePreview from "./Pages/AkademieInvoicePreview"
import InvoicePreview from "./Pages/InvoicePreview"
import InstitutLetterPreview from "./Pages/InstitutLetterPreview"
import ManufakturLetterPreview from "./Pages/ManufakturLetterPreview"
import AkademieLetterPreview from "./Pages/AkademieLetterPreview"


function App() {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([])
  const [foundContacts, setFoundContacts] = useState([])
  const [invoices, setInvoices] = useState([])
  const [foundInvoices, setFoundInvoices] = useState([])
  const [manufakturInvoices, setManufakturInvoices] = useState([])
  const [foundManufakturInvoices, setFoundManufakturInvoices] = useState([])
  const [akademieInvoices, setAkademieInvoices] = useState([])
  const [foundAkademieInvoices, setFoundAkademieInvoices] = useState([])
  const [institutLetters, setInstitutLetters] = useState([])
  const [foundInstitutLetters, setFoundInstitutLetters] = useState([])
  const [manufakturLetters, setManufakturLetters] = useState([])
  const [foundManufakturLetters, setFoundManufakturLetters] = useState([])
  const [akademieLetters, setAkademieLetters] = useState([])
  const [foundAkademieLetters, setFoundAkademieLetters] = useState([])
  const [countries, setCountries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadText, setLoadText] = useState("")
  const pageRefresh = ms => new Promise(res => setTimeout(res, ms))
  const [callMailbox, setCallMailbox] = useState(false)
  const [navPoint, setNavpoint] = useState("Check")
  const [searchValue, setSearchValue] = useState('')
  const [loggedIn, setLoggedin] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [userName, setUsername] = useState("")
  const [userPw, setUserpw] = useState("")
  const [complete, setComplete] = useState(true)
  const [dontChange, setDontChange] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [startContact, setStartContact] = useState(false)
  const [typeNumberValue, setTypeNumberValue] = useState(1)
  const contactDataDefault = {
    id: null,
    type: "",
    title: "",
    firstName: "",
    secondName: "",
    lastName: "",
    gender: "",
    email: "",
    mobile: "",
    phone: "",
    birthday: "",
    country: "Deutschland",
    addressStreet: "",
    addressNumber: "",
    addressZip: "",
    addressCity: "",
    newsletter: "",
    notes: "", 
    tags: "",
    createdAt: "",
    createdFrom: "",
    changedAt: "",
    changedFrom: "",
    businessName: ""
  }
  const [contactData, setContactData] = useState(contactDataDefault)
  const invoiceDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    invoiceNumber: 'SLI-2023-0001',
    invoiceState: '46 846 6335 3',
    nameSex: '',
    firstName: '',
    name: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    docName: '',
    docDate: '',
    quantity: '',
    diagnose: [''],
    products: [],
    sum: 0,
    dates: [],
    paidText: ''
  }
  const akademieInvoiceDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    invoiceNumber: 'SLA-2023-0001',
    invoiceState: 'DE12 1234567',
    nameSex: '',
    firstName: '',
    name: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    paidText: '',
    invoiceText: 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin',
    businessName: ''
  }
  const manufakturInvoiceDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    invoiceNumber: 'SLA-2023-0001',
    invoiceState: 'DE12 1234567',
    nameSex: '',
    firstName: '',
    name: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    paidText: '',
    invoiceText: 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
  }
  const [invoiceData, setInvoiceData] = useState(invoiceDataDefault)
  const [manufakturInvoiceData, setManufakturInvoiceData] = useState(manufakturInvoiceDataDefault)
  const [akademieInvoiceData, setAkademieInvoiceData] = useState(akademieInvoiceDataDefault)
  const letterDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    nameSex: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    country: 'Deutschland',
    letterText: 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin',
    reference: ''
  }
  const akademieLetterDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    nameSex: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    country: 'Deutschland',
    letterText: 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin',
    reference: '',
    businessName: ''
  }
  const manufakturLetterDataDefault = {
    id: null,
    createdAt: '',
    createdFrom: '',
    date: '',
    nameSex: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    country: 'Deutschland',
    letterText: 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin',
    reference: ''
  }
  const [institutLetterData, setInstitutLetterData] = useState(letterDataDefault)
  const [manufakturLetterData, setManufakturLetterData] = useState(manufakturLetterDataDefault)
  const [akademieLetterData, setAkademieLetterData] = useState(akademieLetterDataDefault)

  const fetchData = async () => {
    setIsLoading(true)
    setLoadText("Lade Kontakte")
    await fetch(`/contacts`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.id > b.id) ? 1 : -1)
        setContacts(result)
    })
    setLoadText("Lade Institut Rechnungen")
    await fetch(`/invoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[1] + a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[1] + b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setInvoices(result)
    })
    setLoadText("Lade Institut Geschäftsbriefe")
    await fetch(`/institutLetter`)
      .then((response) => response.json())
      .then((actualData) => {
          const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
          setInstitutLetters(result)
    })
    setLoadText("Lade Manufaktur Rechnungen")
    await fetch(`/manufakturInvoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[1] + a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[1] + b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setManufakturInvoices(result)
    })
    setLoadText("Lade Manufaktur Geschäftsbriefe")
    await fetch(`/manufakturLetter`)
      .then((response) => response.json())
      .then((actualData) => {
          const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
          setManufakturLetters(result)
    })
    setLoadText("Lade Akademie Rechnungen")
    await fetch(`/akademieInvoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[1] + a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[1] + b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setAkademieInvoices(result)
    })
    setLoadText("Lade Akademie Geschäftsbriefe")
    await fetch(`/akademieLetter`)
      .then((response) => response.json())
      .then((actualData) => {
          const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
          setAkademieLetters(result)
    })
    setLoadText("Lade Länderdaten")
    await fetch('https://trial.mobiscroll.com/content/countries.json')
    .then((response) => response.json())
    .then((result) => {
      setCountries(result)
    })
    setLoadText("Lade Verpasste Anrufe")
    await fetch(`/calls`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.date.substring(6, 50) < b.date.substring(6, 50)) ? 1 : -1)
        setData(result)
        checkMailbox(actualData.data)
    });
    setIsLoading(false)
    setLoadText("")
  }


  const checkCall = async (call) => {
    setIsLoading(true)
    setLoadText("Lösche Verpassten Anruf #" + call.id)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        name: call.name,
        number: call.number,
        type: call.type,
        date: call.date,
        checked: 1
      })
    };
    await fetch(`/calls/` + call.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        const callRemoved = data.filter((c) => c.id !== call.id);
        setData(callRemoved)
      });
    setIsLoading(false)
    setLoadText("")
  }

  const checkMailbox = async (d) => {
    d.forEach(element => {
      if(element.number === '08003302424 (061965617022)' && element.checked === "0"){
        setCallMailbox(true)
      }
    });
  }

  const handleCallMailbox = async () => {
    for (const element of data) {
      if(element.number === '08003302424 (061965617022)' && element.checked === "0"){
        await checkCall(element)
        console.log('Checked: Call #' + element.id)
      }
    }
    /*
    data.forEach(element => {
      if(element.number === '08003302424 (061965617022)' && element.checked === "0"){
        checkCall(element)
      }
    });
    */
    setCallMailbox(false)
  }

  const handleCheckAllCalls = async () => {
    for (const element of data) {
      if(element.number !== '08003302424 (061965617022)' && element.checked === "0" && element.number !== 'undefined'){
        await checkCall(element)
        console.log('Checked: Call #' + element.id)
      }
    }
    /*
    data.forEach(element => {
      if(element.number === '08003302424 (061965617022)' && element.checked === "0"){
        checkCall(element)
      }
    });
    */
    setCallMailbox(false)
  }

  const handleContactChange = async (e) => {
    //var myTypeNumber = 1
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value
    })
    if(e.target.name === 'type' && e.target.value !== ''){
      setStartContact(true)
      var kundenValue = 1, lieferantValue = 1, numberValue = 0
      contacts.forEach(contact => {
        numberValue++
        if(contact.type.substring(0,1) === 'K') kundenValue++
        else if (contact.type.substring(0,1) === 'L') lieferantValue++
      });
      if(e.target.value === 'Kunde'){
        setTypeNumberValue(kundenValue)
      }
      else if(e.target.value === 'Lieferant'){
        setTypeNumberValue(lieferantValue)
        /*Just for now
        
        contacts.forEach(async contact => {
            setIsLoading(true)
            setLoadText(contact.firstName + ' ' + contact.lastName  + " to TypeNumber: " + myTypeNumber)
            var datetime = new Date()
            
            const requestOptions = {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ 
                type: 'Kunde_' + myTypeNumber,
                title: contact.title,
                firstName: contact.firstName,
                secondName: contact.secondName,
                lastName: contact.lastName,
                gender: contact.gender,
                email: contact.email,
                mobile: contact.mobile,
                phone: contact.phone,
                birthday: contact.birthday.toString(),
                country: contact.country,
                addressStreet: contact.addressStreet,
                addressNumber: contact.addressNumber,
                addressZip: contact.addressZip,
                addressCity: contact.addressCity,
                newsletter: contact.newsletter,
                notes: contact.notes, 
                tags: contact.tags,
                createdAt: contact.createdAt,
                createdFrom: contact.createdFrom,
                changedAt: datetime.toString(),
                changedFrom: contact.createdFrom,
                businessName: contact.businessName
              })
            };
            await fetch(`/contacts/` + contact.id, requestOptions)
              .then((response) => response.json())
              .then((actualData) => {
                console.log(actualData.data.type + ": " + actualData.data.firstName + " " + actualData.data.lastName)
            });
            setLoadText('')
            setIsLoading(false)
          myTypeNumber++
        });
        
        //Just for now*/
      }
      console.log(numberValue + " = " + kundenValue + " + " + lieferantValue)
    }
  }

  const handleInvoiceChange = (e) => {
    if(e.target && e.target.name === 'diagnose'){
      var oldArr = invoiceData[e.target.name]
      oldArr[e.target.id] = e.target.value
      setInvoiceData({
        ...invoiceData,
        [e.target.name]: oldArr
      })
    }
    else if(e.target && e.target.name === 'dates'){
      var oldArr = invoiceData[e.target.name]
      oldArr[e.target.id] = e.target.value
      setInvoiceData({
        ...invoiceData,
        [e.target.name]: oldArr
      })
    }
    else if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setInvoiceData({
        ...invoiceData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        name: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity
      })
    }
    else if(e.target){
      setInvoiceData({
        ...invoiceData,
        [e.target.name]: e.target.value
      })
    }else{
      setInvoiceData({
        ...invoiceData,
        products: e
      })
    }
  }

  const handleManufakturInvoiceChange = (e) => {
    if(e.target.name === 'change'){
      setDeleteData(true)
      setDontChange(false)
    }
    else if(e.target.name === 'dontChange'){
      setDontChange(false)
    }
    if(manufakturInvoiceData.nameSex !== '' && manufakturInvoiceData.name !== '' && manufakturInvoiceData.invoiceText !== 'Sehr geehrter Herr ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturInvoiceData.invoiceText !== 'Sehr geehrte Frau ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturInvoiceData.invoiceText !== manufakturInvoiceDataDefault.invoiceText && (e.target.name === 'name' || e.target.name === 'nameSex')){
      setDontChange(true)
      return
    }
    if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setManufakturInvoiceData({
        ...manufakturInvoiceData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        name: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity
      })
    }
    else if(e.target){
      setManufakturInvoiceData({
        ...manufakturInvoiceData,
        [e.target.name]: e.target.value
      })
    }else{
      setManufakturInvoiceData({
        ...manufakturInvoiceData,
        products: e
      })
    }
  }

  const handleAkademieInvoiceChange = (e) => {
    if(e.target.name === 'change'){
      setDeleteData(true)
      setDontChange(false)
    }
    else if(e.target.name === 'dontChange'){
      setDontChange(false)
    }
    if(akademieInvoiceData.nameSex !== '' && akademieInvoiceData.name !== '' && akademieInvoiceData.invoiceText !== 'Sehr geehrter Herr ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.invoiceText !== 'Sehr geehrte Frau ' + akademieInvoiceData.name + ',' + '\n\n\n\n' + akademieInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.invoiceText !== akademieInvoiceDataDefault.invoiceText && (e.target.name === 'name' || e.target.name === 'nameSex')){
      setDontChange(true)
      return
    }
    if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setAkademieInvoiceData({
        ...akademieInvoiceData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        name: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity,
        country: contact[0].country,
        businessName: contact[0].businessName
      })
    }
    else if(e.target){
      setAkademieInvoiceData({
        ...akademieInvoiceData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleInstitutLetterChange = (e) => {
    if(e.target.name === 'change'){
      setDeleteData(true)
      setDontChange(false)
    }
    else if(e.target.name === 'dontChange'){
      setDontChange(false)
    }
    if(institutLetterData.nameSex !== '' && institutLetterData.lastName !== '' && institutLetterData.letterText !== 'Sehr geehrter Herr ' + institutLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && institutLetterData.letterText !== 'Sehr geehrte Frau ' + institutLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && institutLetterData.letterText !== letterDataDefault.letterText && (e.target.name === 'lastName' || e.target.name === 'nameSex')){
      setDontChange(true)
      return
    }
    if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setInstitutLetterData({
        ...institutLetterData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity
      })
    }
    else if(e.target){
      setInstitutLetterData({
        ...institutLetterData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleManufakturLetterChange = (e) => {
    if(e.target.name === 'change'){
      setDeleteData(true)
      setDontChange(false)
    }
    else if(e.target.name === 'dontChange'){
      setDontChange(false)
    }
    if(manufakturLetterData.nameSex !== '' && manufakturLetterData.lastName !== '' && manufakturLetterData.letterText !== 'Sehr geehrter Herr ' + manufakturLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturLetterData.letterText !== 'Sehr geehrte Frau ' + manufakturLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturLetterData.letterText !== manufakturLetterDataDefault.letterText && (e.target.name === 'lastName' || e.target.name === 'nameSex')){
      setDontChange(true)
      return
    }
    if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setManufakturLetterData({
        ...manufakturLetterData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity
      })
    }
    else if(e.target){
      setManufakturLetterData({
        ...manufakturLetterData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleAkademieLetterChange = (e) => {
    if(e.target.name === 'change'){
      setDeleteData(true)
      setDontChange(false)
    }
    else if(e.target.name === 'dontChange'){
      setDontChange(false)
    }
    if(akademieLetterData.nameSex !== '' && akademieLetterData.lastName !== '' && akademieLetterData.letterText !== 'Sehr geehrter Herr ' + akademieLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieInvoiceData.letterText !== 'Sehr geehrte Frau ' + akademieLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieLetterData.letterText !== akademieLetterDataDefault.letterText && (e.target.name === 'lastName' || e.target.name === 'nameSex')){
      setDontChange(true)
      return
    }
    if(e.target && e.target.name === 'contacts'){
      var contact = contacts.filter((c) => c.id == e.target.value)
      setAkademieLetterData({
        ...akademieLetterData,
        nameSex: contact[0].gender,
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        street: contact[0].addressStreet,
        streetNumber: contact[0].addressNumber,
        zip: contact[0].addressZip,
        city: contact[0].addressCity,
        country: contact[0].country,
        businessName: contact[0].businessName
      })
    }
    else if(e.target){
      setAkademieLetterData({
        ...akademieLetterData,
        [e.target.name]: e.target.value
      })
    }
  }

  const addDiagnoseButton = () => {
    var oldArr = invoiceData['diagnose']
    oldArr[oldArr.length] = ""
    setInvoiceData({
      ...invoiceData,
      ['diagnose']: oldArr
    })
  }

  const addDateButton = () => {
    var oldArr = invoiceData['dates']
    oldArr[oldArr.length] = ""
    setInvoiceData({
      ...invoiceData,
      ['dates']: oldArr
    })
  }

  const handleContactPush = async () => {
    if((contactData.name === "" && contactData.businessName === "") || (contactData.name === " " && contactData.businessName === " ")|| contactData.gender === ""|| contactData.gender === " " || contactData.type === "" || contactData.createdFrom === ""){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("Book")
    setIsLoading(true)
    setLoadText('Speichere Neuen Kontakt')
    var datetime = new Date()

    await fetch('/contacts', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      type: contactData.type + '_' + typeNumberValue,
      title: contactData.title,
      firstName: contactData.firstName,
      secondName: contactData.secondName,
      lastName: contactData.lastName,
      gender: contactData.gender,
      email: contactData.email,
      mobile: contactData.mobile,
      phone: contactData.phone,
      birthday: contactData.birthday.toString(),
      country: contactData.country,
      addressStreet: contactData.addressStreet,
      addressNumber: contactData.addressNumber,
      addressZip: contactData.addressZip,
      addressCity: contactData.addressCity,
      newsletter: contactData.newsletter,
      notes: contactData.notes,
      tags: contactData.tags,
      createdAt: datetime,
      createdFrom: contactData.createdFrom,
      changedAt: datetime,
      changedFrom: contactData.createdFrom,
      businessName: contactData.businessName
      })
    })
    await fetch(`/contacts`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.lastNidame > b.lastName) ? 1 : -1)
        setContacts(result)
      })
    
    setContactData(contactDataDefault)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleContactUpdate = async () => {
    if((contactData.name === "" && contactData.businessName === "") || (contactData.name === " " && contactData.businessName === " ") || contactData.gender === "" || contactData.gender === " " || contactData.type === "" || contactData.createdFrom === ""){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("Book")
    setIsLoading(true)
    setLoadText('Aktualisiere Kontakt #' + contactData.id)
    var datetime = new Date()
    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        type: contactData.type,
        title: contactData.title,
        firstName: contactData.firstName,
        secondName: contactData.secondName,
        lastName: contactData.lastName,
        gender: contactData.gender,
        email: contactData.email,
        mobile: contactData.mobile,
        phone: contactData.phone,
        birthday: contactData.birthday.toString(),
        country: contactData.country,
        addressStreet: contactData.addressStreet,
        addressNumber: contactData.addressNumber,
        addressZip: contactData.addressZip,
        addressCity: contactData.addressCity,
        newsletter: contactData.newsletter,
        notes: contactData.notes, 
        tags: contactData.tags,
        createdAt: contactData.createdAt,
        createdFrom: contactData.createdFrom,
        changedAt: datetime.toString(),
        changedFrom: contactData.createdFrom,
        businessName: contactData.businessName
      })
    };
    await fetch(`/contacts/` + contactData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
    });

    pageRefresh(2000)
    await fetch(`/contacts`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
        setContacts(result)
      })
    pageRefresh(1000)
    setContactData(contactDataDefault)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
  }

  const handleInvoicePush = async () => {
    console.log(invoiceData)
    if(invoiceData.name === "" || invoiceData.date === " " || invoiceData.date === "" ||invoiceData.gender === " " || invoiceData.gender === "" || invoiceData.type === "" || invoiceData.paidText === "" || invoiceData.docName === "" || invoiceData.docDate === "" || invoiceData.quantity === "" || invoiceData.diagnose === [''] || invoiceData.products === [] || invoiceData.dates === []){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("Invoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Speichere Neue Institut Rechnung')
    var datetime = new Date()

    var mySum = 0
    invoiceData.products.forEach((product) => {
      mySum += (product.price * invoiceData.quantity)
    })

    var myDiagnose = ''
    invoiceData.diagnose.forEach((diagnose) =>{
      myDiagnose += '|' + diagnose
    })

    var myDates = ''
    invoiceData.dates.forEach((myD) => {
      myDates += '|' + myD.toString()
    })

    var myProducts = ''
    invoiceData.products.forEach((myP) => {
      myProducts += '|' + myP.label + ',' + myP.value + ',' + myP.name + ',' + myP.short + ',' + myP.number + ',' + myP.ending + ',' + myP.price
    })

    await fetch('/invoices', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: 'Default',
      date: invoiceData.date.toString(),
      invoiceNumber: invoiceData.invoiceNumber,
      invoiceState: invoiceData.invoiceState,
      nameSex: invoiceData.nameSex,
      firstName: invoiceData.firstName,
      name: invoiceData.name,
      street: invoiceData.street,
      streetNumber: invoiceData.streetNumber,
      zip: invoiceData.zip,
      city: invoiceData.city,
      docName: invoiceData.docName,
      docDate: invoiceData.docDate.toString(),
      quantity: invoiceData.quantity,
      diagnose: myDiagnose,
      products: myProducts,
      sum: mySum.toString(),
      dates: myDates,
      paidText: invoiceData.paidText
      })
    })
    await pageRefresh(2000)
    await fetch(`/invoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setInvoices(result)
    })
    await pageRefresh(1000)
    
    await setNavpoint("Invoices") //Go To All Invoices

    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleManufakturInvoicePush = async () => {
    console.log(manufakturInvoiceData)
    if(manufakturInvoiceData.name === "" || manufakturInvoiceData.date === " "|| manufakturInvoiceData.date === ""|| manufakturInvoiceData.nameSex === " "|| manufakturInvoiceData.nameSex === "" || manufakturInvoiceData.type === "" || manufakturInvoiceData.paidText === "" || manufakturInvoiceData.docName === "" || manufakturInvoiceData.docDate === "" || manufakturInvoiceData.quantity === "" || manufakturInvoiceData.diagnose === [''] || manufakturInvoiceData.products === [] || manufakturInvoiceData.dates === []){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("ManufakturInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Speichere Neue Manufaktur Rechnung')
    var datetime = new Date()

    await fetch('/manufakturInvoices', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: 'Default',
      date: manufakturInvoiceData.date.toString(),
      invoiceNumber: manufakturInvoiceData.invoiceNumber,
      invoiceState: manufakturInvoiceData.invoiceState,
      nameSex: manufakturInvoiceData.nameSex,
      firstName: manufakturInvoiceData.firstName,
      name: manufakturInvoiceData.name,
      street: manufakturInvoiceData.street,
      streetNumber: manufakturInvoiceData.streetNumber,
      zip: manufakturInvoiceData.zip,
      city: manufakturInvoiceData.city,
      paidText: manufakturInvoiceData.paidText,
      invoiceText: manufakturInvoiceData.invoiceText
      })
    })
    await fetch(`/manufakturInvoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setManufakturInvoices(result)
    })
    
    setNavpoint("ManufakturInvoices") //Go To All Invoices

    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleAkademieInvoicePush = async () => {
    if((akademieInvoiceData.name === "" && akademieInvoiceData.businessName === "") || (akademieInvoiceData.name === " " && akademieInvoiceData.businessName === " ") || akademieInvoiceData.date === " " || akademieInvoiceData.date === ""|| akademieInvoiceData.gender === " "|| akademieInvoiceData.gender === "" || akademieInvoiceData.type === "" || akademieInvoiceData.paidText === "" || akademieInvoiceData.invoiceText === ""){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("AkademieInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Speichere Neue Akademie Rechnung')
    var datetime = new Date()

    await fetch('/akademieInvoices', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: 'Default',
      date: akademieInvoiceData.date.toString(),
      invoiceNumber: akademieInvoiceData.invoiceNumber,
      invoiceState: akademieInvoiceData.invoiceState,
      nameSex: akademieInvoiceData.nameSex,
      firstName: akademieInvoiceData.firstName,
      name: akademieInvoiceData.name,
      street: akademieInvoiceData.street,
      streetNumber: akademieInvoiceData.streetNumber,
      zip: akademieInvoiceData.zip,
      city: akademieInvoiceData.city,
      paidText: akademieInvoiceData.paidText,
      invoiceText: akademieInvoiceData.invoiceText,
      businessName: akademieInvoiceData.businessName
      })
    })
    await fetch(`/akademieInvoices`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
        setAkademieInvoices(result)
    })
    
    setNavpoint("AkademieInvoices") //Go To All Invoices

    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleInvoiceUpdate = async () => {
    if(invoiceData.name === "" || invoiceData.date === " "|| invoiceData.date === ""|| invoiceData.gender === " "|| invoiceData.gender === "" || invoiceData.type === "" || invoiceData.paidText === "" || invoiceData.docName === "" || invoiceData.docDate === "" || invoiceData.quantity === "" || invoiceData.diagnose === [''] || invoiceData.products === [] || invoiceData.dates === []){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("Invoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Aktualisiere Institut Rechnung #' + invoiceData.id)

    var mySum = 0
    invoiceData.products.forEach((product) => {
      mySum += (product.price * invoiceData.quantity)
    })

    var myDiagnose = ''
    invoiceData.diagnose.forEach((diagnose) =>{
      myDiagnose += '|' + diagnose
    })

    var myDates = ''
    invoiceData.dates.forEach((myD) => {
      myDates += '|' + myD.toString()
    })

    var myProducts = ''
    invoiceData.products.forEach((myP) => {
      myProducts += '|' + myP.label + ',' + myP.value + ',' + myP.name + ',' + myP.short + ',' + myP.number + ',' + myP.ending + ',' + myP.price
    })

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        createdAt: invoiceData.createdAt,
        createdFrom: invoiceData.createdFrom,
        date: invoiceData.date.toString(),
        invoiceNumber: invoiceData.invoiceNumber,
        invoiceState: invoiceData.invoiceState,
        nameSex: invoiceData.nameSex,
        firstName: invoiceData.firstName,
        name: invoiceData.name,
        street: invoiceData.street,
        streetNumber: invoiceData.streetNumber,
        zip: invoiceData.zip,
        city: invoiceData.city,
        docName: invoiceData.docName,
        docDate: invoiceData.docDate.toString(),
        quantity: invoiceData.quantity,
        diagnose: myDiagnose,
        products: myProducts,
        sum: mySum.toString(),
        dates: myDates,
        paidText: invoiceData.paidText
      })
    };
    await fetch(`/invoices/` + invoiceData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    pageRefresh(2000)
    await fetch(`/invoices`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
            setInvoices(result)
        })
    pageRefresh(1000)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }

  const handleManufakturInvoiceUpdate = async () => {
    if(manufakturInvoiceData.name === "" || manufakturInvoiceData.date === " "|| manufakturInvoiceData.date === ""|| manufakturInvoiceData.nameSex === " "|| manufakturInvoiceData.nameSex === "" || manufakturInvoiceData.type === "" || manufakturInvoiceData.paidText === "" || manufakturInvoiceData.docName === "" || manufakturInvoiceData.docDate === "" || manufakturInvoiceData.quantity === "" || manufakturInvoiceData.diagnose === [''] || manufakturInvoiceData.products === [] || manufakturInvoiceData.dates === []){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("ManufakturInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Aktualisiere Manufaktur Rechnung #' + manufakturInvoiceData.id)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
      createdAt: manufakturInvoiceData.createdAt,
      createdFrom: 'Default',
      date: manufakturInvoiceData.date.toString(),
      invoiceNumber: manufakturInvoiceData.invoiceNumber,
      invoiceState: manufakturInvoiceData.invoiceState,
      nameSex: manufakturInvoiceData.nameSex,
      firstName: manufakturInvoiceData.firstName,
      name: manufakturInvoiceData.name,
      street: manufakturInvoiceData.street,
      streetNumber: manufakturInvoiceData.streetNumber,
      zip: manufakturInvoiceData.zip,
      city: manufakturInvoiceData.city,
      paidText: manufakturInvoiceData.paidText,
      invoiceText: manufakturInvoiceData.invoiceText
      })
    };
    await fetch(`/manufakturInvoices/` + manufakturInvoiceData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    
    await fetch(`/manufakturInvoices`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
            setManufakturInvoices(result)
        })
        
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }

  const checkManufakturInvoice = async (myInvoice) => {

    setNavpoint("ManufakturInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Ändere Zahlungsstatus der Manufaktur Rechnung ' + myInvoice.invoiceNumber)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        createdAt: myInvoice.createdAt,
        createdFrom: 'Default',
        date: myInvoice.date.toString(),
        invoiceNumber: myInvoice.invoiceNumber,
        invoiceState: myInvoice.invoiceState,
        nameSex: myInvoice.nameSex,
        firstName: myInvoice.firstName,
        name: myInvoice.name,
        street: myInvoice.street,
        streetNumber: myInvoice.streetNumber,
        zip: myInvoice.zip,
        city: myInvoice.city,
        paidText: "Betrag dankend erhalten.",
        invoiceText: myInvoice.invoiceText
      })
    };
    await fetch(`/manufakturInvoices/` + myInvoice.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    
    await fetch(`/manufakturInvoices`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
            setManufakturInvoices(result)
        })
        
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }

  const handleAkademieInvoiceUpdate = async () => {
    if((akademieInvoiceData.name === "" && akademieInvoiceData.businessName === "") || (akademieInvoiceData.name === " " && akademieInvoiceData.businessName === " ") || akademieInvoiceData.date === " " || akademieInvoiceData.date === ""|| akademieInvoiceData.gender === " "|| akademieInvoiceData.gender === "" || akademieInvoiceData.type === "" || akademieInvoiceData.paidText === ""){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("AkademieInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Aktualisiere Akademie Rechnung #' + akademieInvoiceData.id)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        createdAt: akademieInvoiceData.createdAt,
        createdFrom: akademieInvoiceData.createdFrom,
        date: akademieInvoiceData.date.toString(),
        invoiceNumber: akademieInvoiceData.invoiceNumber,
        invoiceState: akademieInvoiceData.invoiceState,
        nameSex: akademieInvoiceData.nameSex,
        firstName: akademieInvoiceData.firstName,
        name: akademieInvoiceData.name,
        street: akademieInvoiceData.street,
        streetNumber: akademieInvoiceData.streetNumber,
        zip: akademieInvoiceData.zip,
        city: akademieInvoiceData.city,
        paidText: akademieInvoiceData.paidText,
        invoiceText: akademieInvoiceData.invoiceText,
        businessName: akademieInvoiceData.businessName
      })
    };
    await fetch(`/akademieInvoices/` + akademieInvoiceData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });

    await fetch(`/akademieInvoices`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
            setAkademieInvoices(result)
        })

    setComplete(true)
    setLoadText('')
    setIsLoading(false)
  }

  const checkAkademieInvoice = async (myInvoice) => {
    
    setNavpoint("AkademieInvoices") //Go To All Invoices
    setIsLoading(true)
    setLoadText('Ändere Zahlungsstatus der Akademie Rechnung ' + myInvoice.invoiceNumber)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        createdAt: myInvoice.createdAt,
        createdFrom: myInvoice.createdFrom,
        date: myInvoice.date.toString(),
        invoiceNumber: myInvoice.invoiceNumber,
        invoiceState: myInvoice.invoiceState,
        nameSex: myInvoice.nameSex,
        firstName: myInvoice.firstName,
        name: myInvoice.name,
        street: myInvoice.street,
        streetNumber: myInvoice.streetNumber,
        zip: myInvoice.zip,
        city: myInvoice.city,
        paidText: "Betrag dankend erhalten.",
        invoiceText: myInvoice.invoiceText,
        businessName: myInvoice.businessName
      })
    };
    await fetch(`/akademieInvoices/` + myInvoice.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });

    await fetch(`/akademieInvoices`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (parseFloat(a.invoiceNumber.split('-')[2]) < parseFloat(b.invoiceNumber.split('-')[2])) ? 1 : -1)
            setAkademieInvoices(result)
        })
        
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
  }

  const handleInstitutLetterPush = async () => {
    if(institutLetterData.name === "" || institutLetterData.date === ""|| institutLetterData.date === " "|| institutLetterData.gender === ""|| institutLetterData.gender === " " || institutLetterData.reference === "" || institutLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("InstitutLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Speichere Neuen Institut Geschäftsbrief')
    var datetime = new Date()
    console.log(institutLetterData)
    await fetch('/institutLetter', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: institutLetterData.createdFrom,
      date: institutLetterData.date,
      nameSex: institutLetterData.nameSex,
      firstName: institutLetterData.firstName,
      lastName: institutLetterData.lastName,
      street: institutLetterData.street,
      streetNumber: institutLetterData.streetNumber,
      zip: institutLetterData.zip,
      city: institutLetterData.city,
      country: institutLetterData.country,
      letterText: institutLetterData.letterText,
      reference: institutLetterData.reference
      })
    })
    await pageRefresh(2000)
    await fetch(`/institutLetter`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        setInstitutLetterData(result)
    })
    await pageRefresh(1000)
    
    setNavpoint("InstitutLetters") //CHANGETHIS

    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleManufakturLetterPush = async () => {
    if(manufakturLetterData.name === "" || manufakturLetterData.date === ""|| manufakturLetterData.date === " "|| manufakturLetterData.nameSex === ""|| manufakturLetterData.nameSex === " " || manufakturLetterData.reference === "" || manufakturLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("ManufakturLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Speichere Neuen Manufaktur Geschäftsbrief')
    var datetime = new Date()
    console.log(manufakturLetterData)
    await fetch('/manufakturLetter', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: manufakturLetterData.createdFrom,
      date: manufakturLetterData.date,
      nameSex: manufakturLetterData.nameSex,
      firstName: manufakturLetterData.firstName,
      lastName: manufakturLetterData.lastName,
      street: manufakturLetterData.street,
      streetNumber: manufakturLetterData.streetNumber,
      zip: manufakturLetterData.zip,
      city: manufakturLetterData.city,
      country: manufakturLetterData.country,
      letterText: manufakturLetterData.letterText,
      reference: manufakturLetterData.reference
      })
    })
    await pageRefresh(2000)
    await fetch(`/manufakturLetter`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        setManufakturLetterData(result)
    })
    await pageRefresh(1000)
    
    setNavpoint("ManufakturLetters") //CHANGETHIS

    setComplete(true)
    setLoadText('')
    setIsLoading(false)

  }

  const handleAkademieLetterPush = async () => {
    if((akademieLetterData.name === "" && akademieLetterData.businessName === "") || (akademieLetterData.name === " " && akademieLetterData.businessName === " ") || akademieLetterData.date === "" || akademieLetterData.date === " "|| akademieLetterData.gender === ""|| akademieLetterData.gender === " " || akademieLetterData.reference === "" || akademieLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("AkademieLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Speichere Neuen Akademie Geschäftsbrief')
    var datetime = new Date()

    await fetch('/akademieLetter', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      createdAt: datetime,
      createdFrom: akademieLetterData.createdFrom,
      date: akademieLetterData.date,
      nameSex: akademieLetterData.nameSex,
      firstName: akademieLetterData.firstName,
      lastName: akademieLetterData.lastName,
      street: akademieLetterData.street,
      streetNumber: akademieLetterData.streetNumber,
      zip: akademieLetterData.zip,
      city: akademieLetterData.city,
      country: akademieLetterData.country,
      letterText: akademieLetterData.letterText,
      reference: akademieLetterData.reference,
      businessName: akademieLetterData.businessName
      })
    })
    await fetch(`/akademieLetter`)
      .then((response) => response.json())
      .then((actualData) => {
        const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        setAkademieLetters(result)
    })

    setNavpoint("AkademieLetters")
    setLoadText('')
    setComplete(true)
    setIsLoading(false)

  }

  const handleInstitutLetterUpdate = async () => {
    if(institutLetterData.name === "" || institutLetterData.date === ""|| institutLetterData.date === " "|| institutLetterData.gender === ""|| institutLetterData.gender === " " || institutLetterData.reference === "" || institutLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("InstitutLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Aktualisiere Institut Geschäftsbrief #' + institutLetterData.id)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
      createdAt: institutLetterData.createdAt,
      createdFrom: institutLetterData.createdFrom,
      date: institutLetterData.date,
      nameSex: institutLetterData.nameSex,
      firstName: institutLetterData.firstName,
      lastName: institutLetterData.lastName,
      street: institutLetterData.street,
      streetNumber: institutLetterData.streetNumber,
      zip: institutLetterData.zip,
      city: institutLetterData.city,
      country: institutLetterData.country,
      letterText: institutLetterData.letterText,
      reference: institutLetterData.reference
      })
    };
    await fetch(`/institutLetter/` + institutLetterData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    pageRefresh(2000)
    await fetch(`/institutLetter`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
            setInstitutLetterData(result)
        })
    pageRefresh(1000)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }

  const handleManufakturLetterUpdate = async () => {
    if(manufakturLetterData.name === "" || manufakturLetterData.date === ""|| manufakturLetterData.date === " "|| manufakturLetterData.nameSex === ""|| manufakturLetterData.nameSex === " " || manufakturLetterData.reference === "" || manufakturLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("ManufakturLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Aktualisiere Manufaktur Geschäftsbrief #' + manufakturLetterData.id)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
      createdAt: manufakturLetterData.createdAt,
      createdFrom: manufakturLetterData.createdFrom,
      date: manufakturLetterData.date,
      nameSex: manufakturLetterData.nameSex,
      firstName: manufakturLetterData.firstName,
      lastName: manufakturLetterData.lastName,
      street: manufakturLetterData.street,
      streetNumber: manufakturLetterData.streetNumber,
      zip: manufakturLetterData.zip,
      city: manufakturLetterData.city,
      country: manufakturLetterData.country,
      letterText: manufakturLetterData.letterText,
      reference: manufakturLetterData.reference
      })
    };
    await fetch(`/manufakturLetter/` + manufakturLetterData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    pageRefresh(2000)
    await fetch(`/manufakturLetter`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
            setManufakturLetterData(result)
        })
    pageRefresh(1000)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }

  const handleAkademieLetterUpdate = async () => {
    if((akademieLetterData.name === "" && akademieLetterData.businessName === "") || (akademieLetterData.name === " " && akademieLetterData.businessName === " ") || akademieLetterData.date === "" || akademieLetterData.date === " "|| akademieLetterData.gender === ""|| akademieLetterData.gender === " " || akademieLetterData.reference === "" || akademieLetterData.letterText === ''){
      setComplete(false)
      return
    }
    else{
      setComplete(true)
    }
    setNavpoint("AkademieLetters") //CHANGETHIS
    setIsLoading(true)
    setLoadText('Aktualisiere Akademie Geschäftsbrief #' + akademieLetterData.id)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
      createdAt: akademieLetterData.createdAt,
      createdFrom: akademieLetterData.createdFrom,
      date: akademieLetterData.date,
      nameSex: akademieLetterData.nameSex,
      firstName: akademieLetterData.firstName,
      lastName: akademieLetterData.lastName,
      street: akademieLetterData.street,
      streetNumber: akademieLetterData.streetNumber,
      zip: akademieLetterData.zip,
      city: akademieLetterData.city,
      country: akademieLetterData.country,
      letterText: akademieLetterData.letterText,
      reference: akademieLetterData.reference,
      businessName: akademieLetterData.businessName
      })
    };
    await fetch(`/akademieLetter/` + akademieLetterData.id, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        
    });
    pageRefresh(2000)
    await fetch(`/akademieLetter`)
          .then((response) => response.json())
          .then((actualData) => {
            const result = actualData.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
            setAkademieLetterData(result)
        })
    pageRefresh(1000)
    setComplete(true)
    setLoadText('')
    setIsLoading(false)
    
  }


  const handleContactDetails = (e) => {
    handleNavpoint('NewContact')
    setSearchValue('')
    setContactData({
      id: e.id,
      type: e.type,
      title: e.title,
      firstName: e.firstName,
      secondName: e.secondName,
      lastName: e.lastName,
      gender: e.gender,
      email: e.email,
      mobile: e.mobile,
      phone: e.phone,
      birthday: e.birthday,
      country: e.country,
      addressStreet: e.addressStreet,
      addressNumber: e.addressNumber,
      addressZip: e.addressZip,
      addressCity: e.addressCity,
      newsletter: e.newsletter,
      notes: e.notes, 
      tags: e.tags,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      changedAt: e.changedAt,
      changedFrom: e.changedFrom,
      businessName: e.businessName
    })
    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleInvoiceDetails = (e) => {
    let myDiagnose = e.diagnose.substring(1).split('|')
    let myDates = e.dates.substring(1).split('|')
    let myP = e.products.substring(1).split('|')
    let myProduct = []
    myP.forEach((p) => {
      let helper = p.split(',')
      let thisP = {
        label: helper[0],
        value: helper[1],
        name: helper[2],
        short: helper[3],
        number: helper[4],
        ending: helper[5],
        price: parseFloat(helper[6])
      }
      myProduct.push(thisP)
    })

    
    handleNavpoint("NewInvoice")
    setSearchValue('')
    setInvoiceData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      invoiceNumber: e.invoiceNumber,
      invoiceState: e.invoiceState,
      nameSex: e.nameSex,
      firstName: e.firstName,
      name: e.name,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      docName: e.docName,
      docDate: e.docDate,
      quantity: e.quantity,
      diagnose: myDiagnose,
      products: myProduct,
      sum: parseFloat(e.sum),
      dates: myDates,
      paidText: e.paidText
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleManufakturInvoiceDetails = (e) => {

    handleNavpoint("NewManufakturInvoice")
    setSearchValue('')
    setManufakturInvoiceData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      invoiceNumber: e.invoiceNumber,
      invoiceState: e.invoiceState,
      nameSex: e.nameSex,
      firstName: e.firstName,
      name: e.name,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      paidText: e.paidText,
      invoiceText: e.invoiceText
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleAkademieInvoiceDetails = (e) => {
    
    handleNavpoint("NewAkademieInvoice")
    setSearchValue('')
    setAkademieInvoiceData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      invoiceNumber: e.invoiceNumber,
      invoiceState: e.invoiceState,
      nameSex: e.nameSex,
      firstName: e.firstName,
      name: e.name,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      country: e.country,
      paidText: e.paidText,
      invoiceText: e.invoiceText,
      businessName: e.businessName
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleInstitutLetterDetails = (e) => {

    handleNavpoint("NewInstitutLetter")
    setSearchValue('')
    setInstitutLetterData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      nameSex: e.nameSex,
      firstName: e.firstName,
      lastName: e.lastName,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      country: e.country,
      letterText: e.letterText,
      reference: e.reference
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleManufakturLetterDetails = (e) => {

    handleNavpoint("NewManufakturLetter")
    setSearchValue('')
    setManufakturLetterData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      nameSex: e.nameSex,
      firstName: e.firstName,
      lastName: e.lastName,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      country: e.country,
      letterText: e.letterText,
      reference: e.reference
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleAkademieLetterDetails = (e) => {

    handleNavpoint("NewAkademieLetter")
    setSearchValue('')
    setAkademieLetterData({
      id: e.id,
      createdAt: e.createdAt,
      createdFrom: e.createdFrom,
      date: e.date,
      nameSex: e.nameSex,
      firstName: e.firstName,
      lastName: e.lastName,
      street: e.street,
      streetNumber: e.streetNumber,
      zip: e.zip,
      city: e.city,
      country: e.country,
      letterText: e.letterText,
      reference: e.reference,
      businessName: e.businessName
    })

    setFoundContacts([])
    setFoundInvoices([])
    setFoundAkademieInvoices([])
    setFoundManufakturInvoices([])
    setFoundAkademieLetters([])
    setFoundManufakturLetters([])
    setFoundInstitutLetters([])
    setSearchValue("")
  }

  const handleSearch = (keyword) => {
    setSearchValue(keyword)
    if(keyword !== '' && keyword !== ' '){
      const contactsResult = contacts.filter(obj => {
        return obj.lastName.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.phone.includes(keyword) || obj.mobile.includes(keyword) || obj.addressStreet.toLowerCase().includes(keyword.toLowerCase()) || obj.addressCity.toLowerCase().includes(keyword.toLowerCase()) || obj.businessName?.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundContacts(contactsResult)

      const invoicesResult = invoices.filter(obj => {
        return obj.name.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.invoiceNumber.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundInvoices(invoicesResult)

      const akademieInvoicesResult = akademieInvoices.filter(obj => {
        return obj.name.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.invoiceNumber.toLowerCase().includes(keyword.toLowerCase()) || obj.invoiceText.toLowerCase().includes(keyword.toLowerCase()) || obj.businessName?.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundAkademieInvoices(akademieInvoicesResult)

      const manufakturInvoicesResult = manufakturInvoices.filter(obj => {
        return obj.name.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.invoiceNumber.toLowerCase().includes(keyword.toLowerCase()) || obj.invoiceText.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundManufakturInvoices(manufakturInvoicesResult)

      const institutLetterResult = institutLetters.filter(obj => {
        return obj.lastName.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.letterText.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundInstitutLetters(institutLetterResult)

      const akademieLetterResult = akademieLetters.filter(obj => {
        return obj.lastName.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.letterText.toLowerCase().includes(keyword.toLowerCase()) || obj.businessName?.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundAkademieLetters(akademieLetterResult)

      const manufakturLetterResult = manufakturLetters.filter(obj => {
        return obj.lastName.toLowerCase().includes(keyword.toLowerCase()) || obj.firstName.toLowerCase().includes(keyword.toLowerCase()) || obj.letterText.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundManufakturLetters(manufakturLetterResult)
    }else{
      setFoundContacts([])
      setFoundInvoices([])
      setFoundAkademieInvoices([])
      setFoundManufakturInvoices([])
      setFoundAkademieLetters([])
      setFoundManufakturLetters([])
      setFoundInstitutLetters([])
    }
  }

  const handleNavpoint = (np) => {
    
    if(np === 'NewInvoice' && navPoint !== 'InvoicePreview'){
      let thisYear = new Date().getFullYear().toString()
      let newInvoiceNumber
      if(invoices[0].invoiceNumber.split('-')[0] === thisYear || invoices[0].invoiceNumber.split('-')[0] === thisYear.substring(2,4)){
        let newNumber = parseFloat(invoices[0].invoiceNumber.split('-')[1]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLI-' + thisYear + '-' + newNumber
      }
      else if(invoices[0].invoiceNumber.split('-')[1] === thisYear){
        let newNumber = parseFloat(invoices[0].invoiceNumber.split('-')[2]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLI-' + thisYear + '-' + newNumber
      }
      else if(parseFloat(invoices[0].invoiceNumber.split('-')[1]) === new Date().getFullYear()-1){
        newInvoiceNumber = 'SLI-' + thisYear + '-' + '0001'
      }
      else{
        newInvoiceNumber = 'error'
      }
      let newInvoiceState = invoiceData
      newInvoiceState.invoiceNumber = newInvoiceNumber
      setInvoiceData(newInvoiceState)
    }

    if(np === 'NewManufakturInvoice' && navPoint !== 'ManufakturInvoicePreview'){
      let thisYear = new Date().getFullYear().toString()
      let newInvoiceNumber
      if(manufakturInvoices[0].invoiceNumber.split('-')[0] === thisYear || manufakturInvoices[0].invoiceNumber.split('-')[0] === thisYear.substring(2,4)){
        let newNumber = parseFloat(manufakturInvoices[0].invoiceNumber.split('-')[1]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLN-' + thisYear + '-' + newNumber
      }
      else if(manufakturInvoices[0].invoiceNumber.split('-')[1] === thisYear){
        let newNumber = parseFloat(manufakturInvoices[0].invoiceNumber.split('-')[2]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLN-' + thisYear + '-' + newNumber
      }
      else if(parseFloat(manufakturInvoices[0].invoiceNumber.split('-')[1]) === new Date().getFullYear()-1){
        newInvoiceNumber = 'SLN-' + thisYear + '-' + '0001'
      }
      else{
        newInvoiceNumber = 'error'
      }
      let newInvoiceState = manufakturInvoiceData
      newInvoiceState.invoiceNumber = newInvoiceNumber
      setManufakturInvoiceData(newInvoiceState)
    }

    if(np === 'NewAkademieInvoice' && navPoint !== 'AkademieInvoicePreview'){
      let thisYear = new Date().getFullYear().toString()
      let newInvoiceNumber
      if(akademieInvoices[0].invoiceNumber.split('-')[0] === thisYear || akademieInvoices[0].invoiceNumber.split('-')[0] === thisYear.substring(2,4)){
        let newNumber = parseFloat(akademieInvoices[0].invoiceNumber.split('-')[1]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLA-' + thisYear + '-' + newNumber
      }
      else if(akademieInvoices[0].invoiceNumber.split('-')[1] === thisYear){
        let newNumber = parseFloat(akademieInvoices[0].invoiceNumber.split('-')[2]) + 1
        newNumber = newNumber.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping: false})
        newInvoiceNumber = 'SLA-' + thisYear + '-' + newNumber
      }
      else if(parseFloat(akademieInvoices[0].invoiceNumber.split('-')[1]) === new Date().getFullYear()-1){
        newInvoiceNumber = 'SLA-' + thisYear + '-' + '0001'
      }
      else{
        newInvoiceNumber = 'error'
      }
      let newInvoiceState = akademieInvoiceData
      newInvoiceState.invoiceNumber = newInvoiceNumber
      setAkademieInvoiceData(newInvoiceState)
    }

    if(np !== 'NewInvoice' && np !== 'NewContact' && np !== 'InvoicePreview' && np !== 'NewInstitutLetter' && np !== 'InstitutLetterPreview' && np !== 'NewManufakturInvoice' && np !== 'ManufakturInvoicePreview' && np !== 'NewManufakturLetter' && np !== 'ManufakturLetterPreview' && np !== 'NewAkademieInvoice' && np !== 'AkademieInvoicePreview' && np !== 'NewAkademieLetter' && np !== 'AkademieLetterPreview'){
      setContactData(contactDataDefault)
      setInvoiceData(invoiceDataDefault)
      setManufakturInvoiceData(manufakturInvoiceDataDefault)
      setAkademieInvoiceData(akademieInvoiceDataDefault)
      setInstitutLetterData(letterDataDefault)
      setManufakturLetterData(manufakturLetterDataDefault)
      setAkademieLetterData(akademieLetterDataDefault)
      setComplete(true)
      setSearchValue('')
      setFoundContacts([])
      setFoundInvoices([])
      setFoundAkademieInvoices([])
      setFoundManufakturInvoices([])
      setFoundAkademieLetters([])
      setFoundManufakturLetters([])
      setFoundInstitutLetters([])
      setDeleteData(false)
      setDontChange(false)
      setStartContact(false)
    }
    setNavpoint(np)
  }

  const handleLogin = () => {
    const loggedInUser = sessionStorage.getItem('user');
    const userObject = JSON.parse(loggedInUser);
    if(loggedInUser){
      if(userObject.userName === process.env.REACT_APP_USER && userObject.userPw === process.env.REACT_APP_PW){
        setLoggedin(true)
        fetchData()
      }
      else if(userObject.userName === process.env.REACT_APP_ADMIN ?? userObject.userPw === process.env.REACT_APP_ADDMIN_PW) {
        setLoggedin(true)
        setIsAdmin(true)
        fetchData()
      }
      else{
        sessionStorage.clear();
      }
    }
    else if((userName === process.env.REACT_APP_USER && userPw === process.env.REACT_APP_PW)){
        setLoggedin(true)
        fetchData()
        sessionStorage.setItem('user', JSON.stringify({userName: userName, userPw: userPw}));

    }
    else if((userName === process.env.REACT_APP_ADMIN && userPw === process.env.REACT_APP_ADMIN_PW)){
      setLoggedin(true)
      setIsAdmin(true)
      fetchData()
      sessionStorage.setItem('user', JSON.stringify({userName: userName, userPw: userPw}));

    }else setLoggedin(false)
  }

  useEffect(() => {
    handleLogin()
    //fetchFast()
  }, []);

  useEffect(() => {
    if(deleteData === true){
      setAkademieInvoiceData({...akademieInvoiceDataDefault, invoiceNumber: akademieInvoiceData.invoiceNumber})
      setAkademieLetterData(akademieLetterDataDefault)
      setManufakturInvoiceData({...manufakturInvoiceDataDefault, invoiceNumber: manufakturInvoiceData.invoiceNumber})
      setManufakturLetterData(manufakturLetterDataDefault)
      setInstitutLetterData(akademieLetterDataDefault)
      setDeleteData(false)
    }
  }, [dontChange])


  return (
    <div className="App">
      {!loggedIn ? 
      <>
        <Login 
          setUsername={setUsername}
          setUserpw={setUserpw}
          handleLogin={handleLogin}
        />
        </>
        :
        <>
          <Navbar 
            handleNavpoint={handleNavpoint}
            isAdmin={isAdmin}
            handleSearch={handleSearch}
            searchValue={searchValue}
            foundContacts={foundContacts}
            foundInvoices={foundInvoices}
            foundAkademieInvoices={foundAkademieInvoices}
            foundManufakturInvoices={foundManufakturInvoices}
            foundInstitutLetters={foundInstitutLetters}
            foundAkademieLetters={foundAkademieInvoices}
            foundManufakturLetters={foundManufakturLetters}
            handleContactDetails={handleContactDetails}
            handleInvoiceDetails={handleInvoiceDetails}
            handleAkademieInvoiceDetails={handleAkademieInvoiceDetails}
            handleManufakturInvoiceDetails={handleManufakturInvoiceDetails}
            handleInstitutLetterDetails={handleInstitutLetterDetails}
            handleAkademieLetterDetails={handleAkademieLetterDetails}
            handleManufakturLetterDetails={handleManufakturLetterDetails}
          />

          {navPoint === "Invoices" ?
            <InstitutInvoices
              invoices={invoices}
              handleNavpoint={handleNavpoint}
              handleInvoiceDetails={handleInvoiceDetails}
              isLoading={isLoading}
              loadText={loadText}
            />
            :
            <>
              {navPoint === "ManufakturInvoices" ?
                <ManufakturInvoices
                  manufakturInvoices={manufakturInvoices}
                  handleNavpoint={handleNavpoint}
                  handleManufakturInvoiceDetails={handleManufakturInvoiceDetails}
                  isLoading={isLoading}
                  loadText={loadText}
                  checkManufakturInvoice={checkManufakturInvoice}
                />
                :
                <>
                  {navPoint === "AkademieInvoices" ?
                    <AkademieInvoices
                      akademieInvoices={akademieInvoices}
                      handleNavpoint={handleNavpoint}
                      handleAkademieInvoiceDetails={handleAkademieInvoiceDetails}
                      isLoading={isLoading}
                      loadText={loadText}
                      checkAkademieInvoice={checkAkademieInvoice}
                    />
                    :
                    <>
                      {navPoint === "NewInvoice" ?
                          <NewInvoice
                            handleInvoicePush={handleInvoicePush}
                            handleInvoiceUpdate={handleInvoiceUpdate}
                            invoiceData={invoiceData}
                            handleInvoiceChange={handleInvoiceChange}
                            contacts={contacts}
                            addDiagnoseButton={addDiagnoseButton}
                            addDateButton={addDateButton}
                            handleNavpoint={handleNavpoint}
                            complete={complete}
                          />
                        :
                        <>
                          {navPoint === "NewManufakturInvoice" ?
                            <NewManufakturInvoice
                              handleManufakturInvoicePush={handleManufakturInvoicePush}
                              handleManufakturInvoiceUpdate={handleManufakturInvoiceUpdate}
                              manufakturInvoiceData={manufakturInvoiceData}
                              handleManufakturInvoiceChange={handleManufakturInvoiceChange}
                              contacts={contacts}
                              addDiagnoseButton={addDiagnoseButton}
                              addDateButton={addDateButton}
                              handleNavpoint={handleNavpoint}
                              countries={countries}
                              complete={complete}
                              dontChange={dontChange}
                            />
                            :
                            <>
                              {navPoint === "NewAkademieInvoice" ?
                                <NewAkademieInvoice
                                  handleAkademieInvoicePush={handleAkademieInvoicePush}
                                  handleAkademieInvoiceUpdate={handleAkademieInvoiceUpdate}
                                  akademieInvoiceData={akademieInvoiceData}
                                  handleAkademieInvoiceChange={handleAkademieInvoiceChange}
                                  contacts={contacts}
                                  addDiagnoseButton={addDiagnoseButton}
                                  addDateButton={addDateButton}
                                  handleNavpoint={handleNavpoint}
                                  countries={countries}
                                  complete={complete}
                                  dontChange={dontChange}
                                />
                                :
                                <>
                                  {navPoint === "InstitutLetters" ? 
                                    <InstitutLetter
                                      institutLetters={institutLetters}
                                      handleNavpoint={handleNavpoint}
                                      handleInstitutLetterDetails={handleInstitutLetterDetails}
                                      isLoading={isLoading}
                                      loadText={loadText}
                                    />
                                    :
                                    <>
                                      {navPoint === "ManufakturLetters" ?
                                        <ManufakturLetter
                                          manufakturLetters={manufakturLetters}
                                          handleNavpoint={handleNavpoint}
                                          handleManufakturLetterDetails={handleManufakturLetterDetails}
                                          isLoading={isLoading}
                                          loadText={loadText}
                                        />
                                        :
                                        <>
                                          {navPoint === "AkademieLetters" ?
                                            <AkademieLetter
                                              akademieLetters={akademieLetters}
                                              handleNavpoint={handleNavpoint}
                                              handleAkademieLetterDetails={handleAkademieLetterDetails}
                                              isLoading={isLoading}
                                              loadText={loadText}
                                            />
                                            :
                                            <>
                                              {navPoint === "NewInstitutLetter" ?
                                                <NewInstitutLetter
                                                  handleInstitutLetterPush={handleInstitutLetterPush}
                                                  handleInstitutLetterUpdate={handleInstitutLetterUpdate}
                                                  institutLetterData={institutLetterData}
                                                  handleInstitutLetterChange={handleInstitutLetterChange}
                                                  contacts={contacts}
                                                  handleNavpoint={handleNavpoint}
                                                  complete={complete}
                                                  dontChange={dontChange}
                                                  countries={countries}
                                                />
                                                :
                                                <>
                                                  {navPoint === "NewManufakturLetter" ?
                                                    <NewManufakturLetter
                                                      handleManufakturLetterPush={handleManufakturLetterPush}
                                                      handleManufakturLetterUpdate={handleManufakturLetterUpdate}
                                                      manufakturLetterData={manufakturLetterData}
                                                      handleManufakturLetterChange={handleManufakturLetterChange}
                                                      contacts={contacts}
                                                      handleNavpoint={handleNavpoint}
                                                      countries={countries}
                                                      complete={complete}
                                                      dontChange={dontChange}
                                                    />
                                                    :
                                                    <>
                                                      {navPoint === "NewAkademieLetter" ?
                                                        <NewAkademieLetter
                                                          handleAkademieLetterPush={handleAkademieLetterPush}
                                                          handleAkademieLetterUpdate={handleAkademieLetterUpdate}
                                                          akademieLetterData={akademieLetterData}
                                                          handleAkademieLetterChange={handleAkademieLetterChange}
                                                          contacts={contacts}
                                                          handleNavpoint={handleNavpoint}
                                                          countries={countries}
                                                          complete={complete}
                                                          dontChange={dontChange}
                                                        />
                                                        :
                                                        <>
                                                          {navPoint === 'InstitutLetterPreview' ?
                                                            <InstitutLetterPreview
                                                              handleInstitutLetterPush={handleInstitutLetterPush}
                                                              handleInstitutLetterUpdate={handleInstitutLetterUpdate}
                                                              institutLetterData={institutLetterData}
                                                              handleNavpoint={handleNavpoint}
                                                            />
                                                            :
                                                            <>
                                                              {navPoint === "ManufakturLetterPreview" ?
                                                                <ManufakturLetterPreview
                                                                  handleManufakturLetterPush={handleManufakturLetterPush}
                                                                  handleManufakturLetterUpdate={handleManufakturLetterUpdate}
                                                                  manufakturLetterData={manufakturLetterData}
                                                                  handleNavpoint={handleNavpoint}
                                                                />
                                                                :
                                                                <>
                                                                  {navPoint === "AkademieLetterPreview" ?
                                                                    <AkademieLetterPreview
                                                                      handleAkademieLetterPush={handleAkademieLetterPush}
                                                                      handleAkademieLetterUpdate={handleAkademieLetterUpdate}
                                                                      akademieLetterData={akademieLetterData}
                                                                      handleNavpoint={handleNavpoint}
                                                                    />
                                                                    :
                                                                    <>
                                                                      {navPoint === "InvoicePreview" ?
                                                                        <InvoicePreview
                                                                          handleInvoicePush={handleInvoicePush}
                                                                          handleInvoiceUpdate={handleInvoiceUpdate}
                                                                          invoiceData={invoiceData}
                                                                          handleNavpoint={handleNavpoint}
                                                                        />
                                                                        :
                                                                        <>
                                                                          {navPoint === "ManufakturInvoicePreview" ?
                                                                            <ManufakturInvoicePreview
                                                                              handleManufakturInvoicePush={handleManufakturInvoicePush}
                                                                              handleManufakturInvoiceUpdate={handleManufakturInvoiceUpdate}
                                                                              manufakturInvoiceData={manufakturInvoiceData}
                                                                              handleNavpoint={handleNavpoint}
                                                                            />
                                                                            :
                                                                            <>
                                                                              {navPoint === "AkademieInvoicePreview" ?
                                                                                <AkademieInvoicePreview
                                                                                  handleAkademieInvoicePush={handleAkademieInvoicePush}
                                                                                  handleAkademieInvoiceUpdate={handleAkademieInvoiceUpdate}
                                                                                  akademieInvoiceData={akademieInvoiceData}
                                                                                  handleNavpoint={handleNavpoint}
                                                                                />
                                                                                :
                                                                              
                                                                                <>
                                                                                  {navPoint === "Links" ? 

                                                                                    <Links />
                                                                                    : 
                                                                                    <>
                                                                                      {navPoint === "Book" ?

                                                                                        <Phonebook 
                                                                                          handleContactDetails={handleContactDetails}
                                                                                          contacts={contacts}
                                                                                          handleNavpoint={handleNavpoint}
                                                                                          isLoading={isLoading}
                                                                                          loadText={loadText}
                                                                                        />
                                                                                        :
                                                                                        <>
                                                                                          {navPoint === "NewContact" ? 

                                                                                            <NewContact
                                                                                              handleContactPush={handleContactPush}
                                                                                              handleContactUpdate={handleContactUpdate}
                                                                                              handleContactChange={handleContactChange}
                                                                                              contactData={contactData}
                                                                                              countries={countries}
                                                                                              handleNavpoint={handleNavpoint}
                                                                                              complete={complete}
                                                                                              startContact={startContact}
                                                                                              typeNumberValue={typeNumberValue}
                                                                                            />
                                                                                            :
                                                                                            <MissedCalls 
                                                                                              callMailbox={callMailbox}
                                                                                              handleCallMailbox={handleCallMailbox}
                                                                                              data={data}
                                                                                              navPoint={navPoint}
                                                                                              checkCall={checkCall}
                                                                                              handleNavpoint={handleNavpoint}
                                                                                              isLoading={isLoading}
                                                                                              loadText={loadText}
                                                                                              handleCheckAllCalls={handleCheckAllCalls}

                                                                                            />
                                                                                          }
                                                                                        </>
                                                                                      }
                                                                                    </>
                                                                                  }
                                                                                </>
                                                                              }
                                                                            </>
                                                                          }
                                                                        </>
                                                                      }
                                                                    </>
                                                                  }
                                                                </>
                                                              }
                                                            </>
                                                          }
                                                        </>
                                                      }
                                                    </>
                                                  }
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </>
      }  
    </div>
  );
}

export default App;