import { Page, Text, View, Document, StyleSheet, Font, Svg, Line, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../Logo.png'

const styles = StyleSheet.create({
  body: {
    paddingTop: 60,
    paddingBottom: 130,
    paddingHorizontal: 70
  },
  smallText: {
    fontSize: 6,
    lineHeight: 1.5
  },
  normalText: {
    fontSize: 10,
    lineHeight: 1.5
  },
  normalText2: {
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: 60
  },
  sender: {
    marginBottom: 12,
    marginTop: 70
  },
  date:{
    position: 'absolute',
    right: 70,
    top: 240
  },
  logo:{
    position: 'absolute',
    right: 50,
    top: 120,
    width: 80
  },
  subject:{
    marginTop: 72,
    marginBottom: 28,
    textAlign: 'justify'
  },
  welcome:{
    marginBottom: 16
  },
  text:{
    minHeight: 200
  },
  thanks:{
    marginBottom: 28,
    marginTop: 14
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  footer: {
    position: 'absolute',
    bottom: 40,
    left: 70,
    flexDirection: 'row',
  },
  footerLeft: {
    flexBasis: 200,
    textAlign: 'left'
  },

  footerRight: {
    flexBasis: 300,
    textAlign: 'right',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 100,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  viewer: {
    position: 'fixed',
    top: 160,
    right: 0,
    width: window.innerWidth / 1.01,
    height: window.innerHeight / 1.13,
    border: 'none',
  },
});


function AkademieLetterPreview(props) {
    const {
      handleAkademieLetterPush,
      handleAkademieLetterUpdate,
      akademieLetterData,
      handleNavpoint
    } = props

    Font.registerHyphenationCallback(word => {
      const middle = Math.floor(word.length / 2);
      const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    
      return parts;
    });
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  //ReactPDF.render(<Quixote />);
    return(
      <>
        <div className='Panel'>
          <div style={{marginLeft: '-400px'}}>
            <button style={{position: 'absolute', top: '110px', marginLeft: '100px'}} onClick={e => handleNavpoint('NewAkademieLetter')}>Zurück</button>
            {akademieLetterData.id === null ? <button style={{position: 'absolute', top: '110px'}} onClick={e => handleAkademieLetterPush()}>Speichern</button> : <button style={{position: 'absolute', top: '110px'}} onClick={e => handleAkademieLetterUpdate()}>Update</button>} 
          </div>
        </div>
      <hr/>
        <PDFViewer style={styles.viewer}>
        <Document>
            <Page style={styles.body}>
              <Image
                style={styles.logo}
                src={logo}
              />

              <Text style={[styles.sender, styles.smallText]}>SimLife Akademie UG | Am Mühlberg 7 | 61279 Grävenwiesbach</Text>
              
              <Text style={[styles.normalText]}>{akademieLetterData.firstName} {akademieLetterData.lastName}</Text>
              <Text style={[styles.normalText]}>{akademieLetterData.street} {akademieLetterData.streetNumber}</Text>
              <Text style={[styles.normalText]}>{akademieLetterData.zip} {akademieLetterData.city}</Text>
              <Text style={[styles.normalText]}>{akademieLetterData.country === 'Deutschland' ? 'Deutschland' : akademieLetterData.country}</Text>
              
              <Text style={[styles.date, styles.normalText]}>{akademieLetterData.date.substring(8, 10)}.{akademieLetterData.date.substring(5, 7)}.{akademieLetterData.date.substring(0,4)}</Text>
              <Text style={[styles.subject, styles.normalText]}>{akademieLetterData.reference}</Text>


              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                pageNumber >= 2 ?
                <Text style={[styles.text, styles.normalText]}>
                  {akademieLetterData.letterText}
                </Text> : 
                <Text style={[styles.text, styles.normalText2]}>
                  {akademieLetterData.letterText}
                </Text>
              )} fixed />
              
              
              <View fixed style={styles.footer}>
                <View style={styles.footerLeft}>
                    <Text style={styles.smallText}>SimLife Akademie UG (haftungsbeschränkt)</Text>
                    <Text style={styles.smallText}>Am Mühlberg 7 | 61279 Grävenwiesbach | Deutschland</Text>
                    <Text style={styles.smallText}>Geschäftsführerin: Simona Michel</Text> 
                    <Text style={styles.smallText}>Steuer-Nr.: 603 / 243 / 4166 7</Text>
                    {/*<Text style={styles.smallText}>HRB: 12345 | Registergericht Stadt</Text>*/}
                </View>
                <View style={styles.footerRight}>
                    <Text style={styles.smallText}>info@simlife-akademie.com</Text>
                    <Text style={styles.smallText}>www.simlife-akademie.com</Text>
                    <Text style={styles.smallText}>Amtsgericht Bad Homburg v.d. Höhe | HRB 16393</Text> 
                    {/*<Text style={styles.smallText}>Commerzbank AG</Text>*/}
                    <Text style={styles.smallText}>Commerzbank AG | IBAN: DE56 5134 0013 0651 4764 00 | BIC: COBADEFFXXX</Text>     
                </View>
              </View>
              
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                totalPages >= 2 ? `${pageNumber} / ${totalPages}` : ''
              )} fixed />
            </Page>
          </Document>
        </PDFViewer>
      </>
    )
}

export default AkademieLetterPreview;









