import "./ManufakturLetter.css"
import logo from "../../Logo.png"

function ManufakturLetter(props) {
    const {
        handleManufakturLetterDetails,
        manufakturLetters,
        handleNavpoint,
        isLoading,
        loadText
    } = props
    return(
        <>
        <h1>Manufaktur Geschäftsbriefe</h1>
        <hr/>
        {isLoading ? 
        <div style={{textAlign: 'center'}}>
            <img src={logo} className="Wait-logo" alt="logo" />
            <a className="Wait-text">Bitte Warten</a>
            <p className="Wait-text">{loadText}</p>
        </div>
        :
        <>
            <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('NewManufakturLetter')}}>Neuer Geschäftsbrief</button>
            <table>
                <tr>
                    <th>Nr.</th>
                    <th>Datum</th>
                    <th>Name</th>
                    <th>Vorname</th>
                </tr>
                <br></br>
                {manufakturLetters.length > 0 && (
                    manufakturLetters.map(manufakturLetter =>(
                    <>
                        <tr className="clickabale" onClick={() => handleManufakturLetterDetails(manufakturLetter)}>
                            <th>{manufakturLetter.id}</th>
                            <th>{manufakturLetter.date}</th>
                            <th>{manufakturLetter.lastName}</th>
                            <th>{manufakturLetter.firstName}</th>
                        </tr>
                    </>
                    ))
                )}
            </table>
        </>}
        </>
    )
}

export default ManufakturLetter;