import "./Phonebook.css"
import logo from "../../Logo.png"

function Phonebook(props) {
    const {
        handleContactDetails,
        contacts,
        handleNavpoint,
        isLoading,
        loadText
    } = props
    return(
        <>
        <h1>Kontakt</h1>
        <hr/>
        {isLoading ? 
        <div style={{textAlign: 'center'}}>
            <img src={logo} className="Wait-logo" alt="logo" />
            <a className="Wait-text">Bitte Warten</a>
            <p className="Wait-text">{loadText}</p>
        </div>
        :
        <>
        <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('NewContact')}}>Neuer Kontakt</button>
        <table>
            <tr>
                <th>Geschlecht</th>
                <th>Name</th>
                <th>Vorname</th>
                <th>Mobile</th>
                <th>Festnetz</th>
                <th>Email</th>
                <th>News</th>
            </tr>
            <br></br>
            {contacts.length > 0 && (
                contacts.sort((a, b) => a.lastName > b.lastName ? 1 : -1).map(contact =>(
                <>
                    <tr className="clickabale" onClick={() => handleContactDetails(contact)}>
                        <th>{contact.gender === "female" ? "Frau" : contact.gender === "male" ? "Herr" : contact.gender === 'firma' ? 'Firma' : contact.gender}</th>
                        <th>{contact.lastName === '' && contact.businessName !== '' ? contact.businessName : contact.lastName}</th>
                        <th>{contact.firstName}</th>
                        <th>{contact.mobile}</th>
                        <th>{contact.phone}</th>
                        <th>{contact.email}</th>
                        <th>{contact.newsletter}</th>
                    </tr>
                </>
                ))
            )}
        </table>
        </>
        }
        
        </>
    )
}

export default Phonebook;