import "./MissedCalls.css"
import logo from "../../Logo.png"

function MissedCalls(props) {
    const {
        callMailbox,
        handleCallMailbox,
        data,
        navPoint,
        checkCall,
        handleNavpoint,
        isLoading,
        loadText,
        handleCheckAllCalls
    } = props
    return(
        <> 

  <h1>Anrufe in Abwesenheit</h1>
  <hr/>
  {isLoading ? 
      <div style={{textAlign: 'center'}}>
          <img src={logo} className="Wait-logo" alt="logo" />
          <a className="Wait-text">Bitte Warten</a>
          <p className="Wait-text">{loadText}</p>

      </div>
      :
      <>
    <div className="CallMailbox">
      {callMailbox ? <button className="CallMailbox" onClick={() => handleCallMailbox()}>Mailbox Anrufen!!!</button> : <div className="CallMailbox"></div>}
    </div>
      {navPoint === "Check" ? 
        <>
          <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('All')}}>Historie</button>
          <button style={{marginLeft: '70%'}} onClick={(e) => {handleCheckAllCalls()}}>Alle Löschen</button>
        </>
        :
        <button style={{marginLeft: '70%'}} onClick={(e) => {handleNavpoint('Check')}}>Aktuell</button>
      }
    <table>
      <tr>
        {/*<th>Name</th>*/}
        <th>Nummer</th>
        {/*<th>Art</th>*/}
        <th>Datum</th>
        <th>OK</th>
      </tr>
      <br></br>
      {data.length > 0 && (
        data.sort((a, b) => a.id > b.id ? -1 : 1).map(call =>(
          (navPoint === "Check" && (call.checked !== "1" && call.number !== 'undefined' && call.number !== '08003302424 (061965617022)') ?
          <>
          <tr>
            {/*<th>{call.name}</th>*/}
            <th>{call.number}</th>
            {/*<th>{call.type}</th>*/}
            <th>{call.date.substring(4, 25)}</th>
            <th>{<input type={"checkbox"} onChange={() => checkCall(call)}/>}</th>
            {/*call.name === "" ? <button>Speichern</button> : <button>Bearbeiten</button>*/}
          </tr>
          </>
          :
          <>
          {navPoint === "All" ? 
          <>
            <tr>
              {/*<th>{call.name}</th>*/}
              <th>{call.number}</th>
              {/*<th>{call.type}</th>*/}
              <th>{call.date.substring(4, 25)}</th>
              <th>{<input type={"checkbox"} checked={call.checked==="1"?true:false}/>}</th>
              {/*call.name === "" ? <button>Speichern</button> : <button>Bearbeiten</button>*/}
            </tr>
          </> 
          : 
          <>
            
          </> 
          }
          </>
          )
        ))
      )}
    </table>
    <hr/>
      </>
  }


</>
    )
}

export default MissedCalls;