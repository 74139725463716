import { Page, Text, View, Document, StyleSheet, Font, Svg, Line, PDFViewer } from '@react-pdf/renderer';
import MultiSelect from "react-select";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: "#38b06c"
  },
  author: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 50,
    color: "#38b06c"
  },
  sender: {
    fontSize: 8,
    margin: 1,
    marginLeft: 10,
    color: "#38b06c"
  },
  recipient: {
    marginLeft: 12,
    fontSize: 13,
  },
  invoiceNumber:{
    position: 'absolute',
    fontSize: 9,
    left: 370,
    top: 150
  },
  invoiceNumberValue:{
    position: 'absolute',
    fontSize: 9,
    right: 47,
    top: 150
  },
  invoiceDate:{
    position: 'absolute',
    fontSize: 9,
    left: 370,
    top: 180
  },
  invoiceDateValue:{
    position: 'absolute',
    fontSize: 9,
    right: 47,
    top: 180
  },
  invoiceState:{
    position: 'absolute',
    fontSize: 9,
    left: 370,
    top: 165
  },
  invoiceStateValue:{
    position: 'absolute',
    fontSize: 9,
    right: 47,
    top: 165
  },
  invoiceSubject:{
    fontSize: 12,
    margin: 12,
    marginTop: 40,
    textAlign: 'justify',
    fontFamily: 'Oswald'
  },
  invoiceTitle:{
    fontSize: 14,
    marginBottom: 12,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  text: {
    marginLeft: 12,
    marginRight: 12,
    lineHeight: 1.2,
    fontSize: 13,
    textAlign: 'justify',
  },
  smallText: {
    marginLeft: 12,
    marginRight: 12,
    lineHeight: 1.2,
    fontSize: 9,
    textAlign: 'justify',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    marginTop: 24,
    flexDirection: 'row',
  },
  left: {
    flexBasis: 440,
  },

  right: {
    textAlign: 'right',
  },
  footer: {
    position: 'absolute',
    bottom:40,
    marginTop: 24,
    left: 24,
    flexDirection: 'row',
  },
  footerLeft: {
    flexBasis: 350
  },

  footerRight: {
    textAlign: 'right',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  viewer: {
    position: 'fixed',
    top: 100,
    right: 0,
    width: window.innerWidth / 3,
    height: window.innerHeight / 1.2,
    border: 'none',
  },
});


function institutInvoice(props) {
    const {
      handleInvoicePush,
      handleInvoiceUpdate,
      invoiceData,
      handleInvoiceChange,
      contacts,
      addDiagnoseButton,
      addDateButton,
      handleNavpoint,
      complete
    } = props

  //Font.registerHyphenationCallback(word => [word]);

  //Products
  var products = [
    {label: 'Massage 30min', value: 'Massage', name: 'Massage', short: 'KMT', number: 30, ending: 'min', price: 35.00},
    {label: 'Massage 60min', value: 'Massage', name: 'Massage', short: 'KMT', number: 60, ending: 'min', price: 79.00},
    {label: 'Massage 75min', value: 'Massage', name: 'Massage', short: 'KMT', number: 75, ending: 'min', price: 35.00},
    {label: 'Massage 90min', value: 'Massage', name: 'Massage', short: 'KMT', number: 90, ending: 'min', price: 35.00},
    {label: 'Manuelle Therapie', value: 'Manuelle Therapie', name:  'Manuele Therapie', short:  'MT', number: 30, ending: 'min', price: 35.00},
    {label: 'Physiotherapie', value: 'Physiotherapie', name:  'Physiotherapie', short:  'KG', number: 30, ending: 'min', price: 35.00},
    {label: 'Manuelle Lymphdrainage', value: 'Manuelle Lymphdrainage', name:  'Manuelle Lymphdrainage', short:  'MLD', number: 30, ending: 'min', price: 35.00},
    {label: 'Heißluft', value: 'Heißluft', name:  'Heißluft', short: '', number: 20, ending: 'min', price: 8.60},
    {label: 'Fango', value: 'Fango', name:  'Fango', short:  '', number: 20, ending: 'min', price: 13.60},
  ]

  var sum = 0
  invoiceData.products.forEach((product) => {
    sum += (product.price * invoiceData.quantity)
  })
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  //ReactPDF.render(<Quixote />);
    return(
      <>
        {invoiceData.id === null ? <h1>SimLife Institut: Neue Rechnung</h1> : <h1>{invoiceData.invoiceNumber}</h1>}
        <hr/>
        <br/>
        <br/>
        <div className='Panel'>
          {complete === false ? 
          <div style={{width: "100%", backgroundColor: "red", color: "white", textAlign: "center", height: "30px", justifyContent: "center"}}>
            <a>Bitte Pflichtfelder bearbeiten</a>
          </div>
          :
          <></>
          }
          <div>
            {invoiceData.id === null ? <button onClick={e => handleInvoicePush()}>Speichern</button> : <button onClick={() => handleInvoiceUpdate()}>Update</button>} 
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('InvoicePreview')}>Vorschau</button>
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('Invoices')}>Abbrechen</button>
          </div>
        </div>
      <div className="Panel">
        <div class="row">
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="contacts" className="smallSpace">Kontakt: </lable>
            </div>
            <div className="upperSpaceLable">
            {complete === false && invoiceData.nameSex === "" ? <lable htmlFor="nameSex" style={{color: "red"}} className="smallSpace">Geschlecht: </lable> : <lable htmlFor="nameSex" className="smallSpace">Geschlecht: </lable>}
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="firstName" className="smallSpace">Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && invoiceData.name === "" ? <lable htmlFor="name" className="smallSpace" style={{color: "red"}}>Name: </lable> : <lable htmlFor="name" className="smallSpace">Name: </lable>}
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
            <div className="upperSpace">
                    <select id="contacts" name="contacts" className="space" style={{width: '80%'}} onChange={handleInvoiceChange}>
                      <option value="" disabled selected>Kontakt auswählen</option>
                      {contacts.map(c => (
                        <>
                          <option value={c.id} label={c.lastName + ', ' + c.firstName}/>
                        </>
                      ))}
                    </select>
                </div>
              <div className="upperSpace">
                  <select id="nameSex" name="nameSex" className="space" style={{width: '80%'}} onChange={handleInvoiceChange} value={invoiceData.nameSex === 'male' ? 'Herr' : invoiceData.nameSex === 'female' ? 'Frau' : ''}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="Frau" label='Frau'/>
                    <option value="Herr" label='Herr'/>
                    <option value="firma" label='Firma'/>
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="firstName"
                    className="space"
                    name="firstName"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.firstName}
                    onChange={handleInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="name"
                    className="space"
                    name="name"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.name}
                    onChange={handleInvoiceChange}
                  />
              </div>
          </div>
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="street" className="smallSpace">Straße: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="streetNumber" className="smallSpace">Straßennummer: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="zip" className="smallSpace">Postleitzahl: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="city" className="smallSpace">Stadt: </lable>
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
          <div className="upperSpace">
                  <input
                    id="street"
                    className="space"
                    name="street"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.street}
                    onChange={handleInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="streetNumber"
                    className="space"
                    name="streetNumber"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.streetNumber}
                    onChange={handleInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="zip"
                    className="space"
                    name="zip"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.zip}
                    onChange={handleInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="city"
                    className="space"
                    name="city"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.city}
                    onChange={handleInvoiceChange}
                  />
              </div>
          </div>
        </div>
      </div>
      <hr/>
      <div className="Panel">
        <div class="row">
          <div class="column">
          
            <div className="upperSpaceLable">
              {complete === false && invoiceData.date === "" ? <lable htmlFor="date" className="smallSpace" style={{color: "red"}}>Rechnungsdatum: </lable> : <lable htmlFor="date" className="smallSpace">Rechnungsdatum: </lable>}
            </div>
            {invoiceData.id === null ?
            <div className="upperSpaceLable">
              {complete === false && invoiceData.paidText === "" ? <lable htmlFor="paidText" className="smallSpace" style={{color: "red"}}>Bezahlt: </lable> : <lable htmlFor="paidText" className="smallSpace">Bezahlt: </lable>}
            </div>
            : <></>}
          </div>
          <div class="column" style={{style: '400px'}}>
            <div className="upperSpace">
                  <input
                    id="date"
                    className="space"
                    name="date"
                    type="date"
                    style={{width: '320px'}}
                    value={invoiceData.date}
                    onChange={handleInvoiceChange}
                  />
              </div>
              {invoiceData.id === null ?
              <div className="upperSpace">
                  <select id="paidText" name="paidText" className="space" style={{width: '80%'}} onChange={handleInvoiceChange} value={invoiceData.paidText === "Betrag dankend erhalten." ? 'Bereits bezahlt' : invoiceData.paidText === "Sofort zahlbar ohne Abzug." ? 'Nicht bezahlt' : ''}>
                    <option value="" disabled >Bitte wählen</option>
                    <option value="Betrag dankend erhalten.">Bereits bezahlt</option>
                    <option value="Sofort zahlbar ohne Abzug.">Nicht bezahlt</option>
                  </select>
              </div>
              : <></>
              }
          </div>
          <div class="column">
          <div className="upperSpaceLable">
              <lable htmlFor="invoiceNumber" className="smallSpace" style={{marginLeft: '-40px'}}>Rechnungsnummer: </lable>
            </div>
          </div>
          <div class="column" style={{width: '400px'}}>
              <div className="upperSpace" style={{marginTop: '33px'}}>
                  <a
                    id="invoiceNumber"
                    className="space"
                    name="invoiceNumber"
                    style={{width: '80%'}}
                  >{invoiceData.invoiceNumber}</a>
              </div>
          </div>
        </div>
      </div>
      <hr/>
      <div className='Panel'>
        <div className='row'>
          <div className='column'>
            <div className="upperSpaceLable">
              {complete === false && invoiceData.docName === "" ? <lable htmlFor="docName" className="smallSpace" style={{color: "red"}}>Name Arzt: </lable> : <lable htmlFor="docName" className="smallSpace">Name Arzt: </lable>}
            </div>
            <div className="upperSpaceLable">
              {complete === false && invoiceData.docDate === "" ? <lable htmlFor="docDate" className="smallSpace" style={{color: "red"}}>Rezeptdatum: </lable> : <lable htmlFor="docDate" className="smallSpace">Rezeptdatum: </lable>}
            </div>
            <div className="upperSpaceLable" >{/*style={{marginTop: '10px'}}*/}
              {complete === false && invoiceData.diagnose === "" ? <lable htmlFor="diagnose" className="smallSpace" style={{color: "red"}}>Diagnose: </lable> : <lable htmlFor="diagnose" className="smallSpace">Diagnose: </lable>}
              {/*<button onClick={e => addDiagnoseButton()}>+</button>*/}
            </div>
          </div>
          <div className='column' style={{width: '400px'}}>
              <div className="upperSpace">
                  <input
                    id="docName"
                    className="space"
                    name="docName"
                    type="text"
                    style={{width: '80%'}}
                    value={invoiceData.docName}
                    onChange={handleInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="docDate"
                    className="space"
                    name="docDate"
                    type="date"
                    style={{width: '80%'}}
                    value={invoiceData.docDate}
                    onChange={handleInvoiceChange}
                  />
              </div>
              {invoiceData.diagnose.map((diagnose, index) =>
                <div className="upperSpace">
                    <textarea 
                      id={index} 
                      name="diagnose" 
                      style={{width: '80%'}} 
                      rows="4" 
                      cols="50" 
                      value={invoiceData.diagnose[index]} 
                      onChange={handleInvoiceChange}
                    />
                      
                </div>
              )}
          </div>
            <div className='column'>
              <div className="upperSpaceLable">
              {complete === false && invoiceData.quantity === "" ? <lable htmlFor="quantity" className="smallSpace" style={{color: "red"}}>Anzahl Verordnung: </lable> : <lable htmlFor="quantity" className="smallSpace">Anzahl Verordnung: </lable>}
              </div>
              <div className="upperSpaceLable">
              {complete === false && invoiceData.products === [''] ? <lable htmlFor="products" className="smallSpace" style={{color: "red"}}>Verordnungen: </lable> : <lable htmlFor="products" className="smallSpace">Verordnungen: </lable>}
              </div>
            </div>
            <div className='column' style={{width: '400px'}}>
              <div className="upperSpace">
                    <input
                      id="quantity"
                      className="space"
                      name="quantity"
                      type="number"
                      min="0"
                      style={{width: '80%'}}
                      value={invoiceData.quantity}
                      onChange={handleInvoiceChange}
                    />
                </div>
                
                <div className="upperSpace" style={{width: '320px', color: 'black'}}>
                  <MultiSelect
                    options={products}
                    placeholder="Verordnungen"
                    value={invoiceData.products}
                    onChange={handleInvoiceChange}
                    isSearchable={true}
                    isMulti
                  />
                </div>
            </div>
          </div>
      </div>
      <hr/>
      <div>
        {complete === false && invoiceData.dates === [''] ? <a className="upperSpace" style={{marginLeft: '-1050px', color: "red"}}>Daten: </a> : <a className="upperSpace" style={{marginLeft: '-1050px'}}>Daten: </a>}
        <button style={{marginLeft: '20px'}} onClick={e => addDateButton()}>+</button>
      </div>
      
      <div className="upperSpace" style={{display:'flex' , flexDirection: 'row', flexWrap: 'wrap', marginLeft: '150px', width: '1300px', marginBottom: '80px'}}>
        
        {invoiceData.dates.map((date, index) =>
              <div className="upperSpace">
                <input
                  id={index}
                  className="space"
                  name="dates"
                  type="date"
                  value={invoiceData.dates[index]}
                  onChange={handleInvoiceChange}
                />
            </div>
            )}
        </div>
      </>
    )
}

export default institutInvoice;









