import { Font } from '@react-pdf/renderer';

function newManufakturInvoice(props) {
    const {
      handleManufakturInvoicePush,
      handleManufakturInvoiceUpdate,
      manufakturInvoiceData,
      handleManufakturInvoiceChange,
      contacts,
      handleNavpoint,
      countries,
      complete,
      dontChange
    } = props

  //Font.registerHyphenationCallback(word => [word]);
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  //ReactPDF.render(<Quixote />);
    return(
      <>
        {manufakturInvoiceData.id === null ? <h1>Manufaktur: Neue Rechnung</h1> : <h1>{manufakturInvoiceData.invoiceNumber + ": " + manufakturInvoiceData.name} </h1>}
        <hr/>
        <br/>
        <br/>
        {dontChange === false ? <>
        <div className='Panel'>
          {complete === false ? 
          <div style={{width: "100%", backgroundColor: "red", color: "white", textAlign: "center", height: "30px", justifyContent: "center"}}>
            <a>Bitte Pflichtfelder bearbeiten</a>
          </div>
          :
          <></>
          }
          <div>
            {manufakturInvoiceData.id === null ? <button onClick={e => handleManufakturInvoicePush()}>Speichern</button> : <button onClick={() => handleManufakturInvoiceUpdate()}>Update</button>} 
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('ManufakturInvoicePreview')}>Vorschau</button>
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('ManufakturInvoices')}>Abbrechen</button>
          </div>
        </div>
      <div className="Panel">
        <div class="row">
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="contacts" className="smallSpace">Kontakt: </lable>
            </div>
            <div className="upperSpaceLable">
            {complete === false && manufakturInvoiceData.nameSex === "" ? <lable htmlFor="nameSex" style={{color: "red"}} className="smallSpace">Geschlecht: </lable> : <lable htmlFor="nameSex" className="smallSpace">Geschlecht: </lable>}

            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="firstName" className="smallSpace">Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && manufakturInvoiceData.name === "" ? <lable htmlFor="name" className="smallSpace" style={{color: "red"}}>Name: </lable> : <lable htmlFor="lastname" className="smallSpace">Name: </lable>}
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
            <div className="upperSpace">
                    <select disabled={manufakturInvoiceData.name === "" || manufakturInvoiceData.date === " " || manufakturInvoiceData.type === "" || manufakturInvoiceData.paidText === "" || manufakturInvoiceData.invoiceText === "" || manufakturInvoiceData.invoiceText === 'Sehr geehrter Herr ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' || manufakturInvoiceData.invoiceText === 'Sehr geehrte Frau ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' ? false : true} id="contacts" name="contacts" className="space" style={{width: '80%'}} onChange={handleManufakturInvoiceChange}>
                      <option value="" disabled selected>Kontakt auswählen</option>
                      {contacts.map(c => (
                        <>
                          <option value={c.id} label={c.lastName + ', ' + c.firstName}/>
                        </>
                      ))}
                    </select>
                </div>
              <div className="upperSpace">
                  <select id="nameSex" name="nameSex" className="space" style={{width: '80%'}} onChange={handleManufakturInvoiceChange} value={manufakturInvoiceData.nameSex}>
                    <option value="" disabled>Bitte wählen</option>
                    <option value="female" label='Frau'/>
                    <option value="male" label='Herr'/>
                    <option value="firma" label='Firma'/>
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="firstName"
                    className="space"
                    name="firstName"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.firstName}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="name"
                    className="space"
                    name="name"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.name}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
          </div>
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="street" className="smallSpace">Straße: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="streetNumber" className="smallSpace">Straßennummer: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="zip" className="smallSpace">Postleitzahl: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="city" className="smallSpace">Stadt: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="country" className="smallSpace">Land: </lable>
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
          <div className="upperSpace">
                  <input
                    id="street"
                    className="space"
                    name="street"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.street}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="streetNumber"
                    className="space"
                    name="streetNumber"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.streetNumber}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="zip"
                    className="space"
                    name="zip"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.zip}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="city"
                    className="space"
                    name="city"
                    type="text"
                    style={{width: '80%'}}
                    value={manufakturInvoiceData.city}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
              <select id="country" name="country" className="upperSpace" style={{width: '80%'}} onChange={handleManufakturInvoiceChange} value={manufakturInvoiceData.country}>
                  <option value="" disabled selected>Bitte wählen</option>
                  <option value="Deutschland">Deutschland</option>
                  {countries.map(c => (
                    <>
                      <option value={c.text}>{c.text}</option>
                    </>
                  ))}
                </select>
          </div>
        </div>
      </div>
      <hr/>
      {(manufakturInvoiceData.name !== '' && manufakturInvoiceData.nameSex !== '') || manufakturInvoiceData.id !== null ? 
      <>
      <div className="Panel">
        <div class="row">
          <div class="column">
            <div className="upperSpaceLable">
              {complete === false && manufakturInvoiceData.date === "" ? <lable htmlFor="date" className="smallSpace" style={{color: "red"}}>Briefdatum: </lable> : <lable htmlFor="date" className="smallSpace">Datum: </lable>}
            </div>
          </div>
          <div class="column" style={{style: '400px'}}>
            <div className="upperSpace">
                  <input
                    id="date"
                    className="space"
                    name="date"
                    type="date"
                    style={{width: '320px'}}
                    value={manufakturInvoiceData.date}
                    onChange={handleManufakturInvoiceChange}
                  />
              </div>
          </div>
          <div class="column">
          {manufakturInvoiceData.id === null ?
            <div className="upperSpaceLable">
              {complete === false && manufakturInvoiceData.paidText === "" ? <lable htmlFor="paidText" className="smallSpace" style={{color: "red"}}>Bezahlt: </lable> : <lable htmlFor="paidText" className="smallSpace">Bezahlt: </lable>}
            </div>
            : <></>}
          </div>
          <div class="column" style={{style: '400px'}}>
            {manufakturInvoiceData.id === null ?
            <div className="upperSpace">
              <select id="paidText" name="paidText" className="space" style={{width: '320px'}} onChange={handleManufakturInvoiceChange} value={manufakturInvoiceData.paidText}>
                <option value="" disabled >Bitte wählen</option>
                <option value="Betrag dankend erhalten.">Betrag dankend erhalten.</option>
                <option value="Sofort zahlbar ohne Abzug.">Sofort zahlbar ohne Abzug.</option>
              </select>
            </div>
            : <></>
            }
          </div>
        </div>
      </div>
      <hr/></> : <></>}
      {(manufakturInvoiceData.name !== '' && manufakturInvoiceData.nameSex !== '' && manufakturInvoiceData.date !== '' && manufakturInvoiceData.paidText !== '') || manufakturInvoiceData.id !== null ?
        <div className='Panel'>
        <div className='row'>
          <div className='column'>
            <div className="upperSpaceLable">
              {complete === false && manufakturInvoiceData.invoiceNumber === "" ? <lable htmlFor="invoiceNumber" className="smallSpace" style={{color: "red"}}>Rechnungsnummer: </lable> : <lable htmlFor="invoiceNumber" className="smallSpace">Rechnungsnummer: </lable>}
            </div>
            <div className="upperSpaceLable" >{/*style={{marginTop: '10px'}}*/}
              {complete === false && manufakturInvoiceData.invoiceText === "" ? <lable htmlFor="invoiceText" className="smallSpace" style={{color: "red"}}>Rechnungstext: </lable> : <lable htmlFor="invoiceText" className="smallSpace">Rechnungstext: </lable>}
              {/*<button onClick={e => addDiagnoseButton()}>+</button>*/}
            </div>
          </div>
          <div className='column' style={{width: '1110px', marginRight: '-135px'}}>
              <div className="upperSpace">
                    <a
                      id="invoiceNumber"
                      className="space"
                      name="invoiceNumber"
                      style={{width: '80%'}}
                    >{manufakturInvoiceData.invoiceNumber}</a>
                </div>
                <div className="upperSpace">
                    <textarea 
                      id='invoiceText'
                      name="invoiceText" 
                      style={{width: '80%', marginBottom: '64px'}} 
                      rows="12" 
                      cols="50" 
                      value={
                        manufakturInvoiceData.invoiceText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturInvoiceData.nameSex === 'male' && manufakturInvoiceData.name !== '' ? 'Sehr geehrter Herr ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : manufakturInvoiceData.invoiceText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && manufakturInvoiceData.nameSex === 'female' && manufakturInvoiceData.name !== '' ? 'Sehr geehrte Frau ' + manufakturInvoiceData.name + ',' + '\n\n\n\n' + manufakturInvoiceData.paidText + '\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : manufakturInvoiceData.invoiceText
                      }
                      onChange={handleManufakturInvoiceChange}
                    />
                </div>
          </div>
        </div>
      </div> : <></>}
      </> : <>
        <a>Bei nachträglichen Änderungen werden alle Daten zurückgesetzt</a>
        <button name='change' onClick={handleManufakturInvoiceChange}>Daten löschen</button>
        <button name='dontChange' onClick={handleManufakturInvoiceChange}>Abbrechen</button>
      </>}

      </>
    )
}

export default newManufakturInvoice;









