import { Font } from '@react-pdf/renderer';

function newAkademieLetter(props) {
    const {
      handleAkademieLetterPush,
      handleAkademieLetterUpdate,
      akademieLetterData,
      handleAkademieLetterChange,
      contacts,
      handleNavpoint,
      countries,
      complete,
      dontChange
    } = props

  //Font.registerHyphenationCallback(word => [word]);
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  //ReactPDF.render(<Quixote />);
    return(
      <>
        {akademieLetterData.id === null ? <h1>SimLife Akademie: Neuer Geschäftsbrief</h1> : <h1>{akademieLetterData.id + ": " + akademieLetterData.lastName} </h1>}
        <hr/>
        <br/>
        <br/>
        {dontChange === false ? <>
        <div className='Panel'>
          {complete === false ? 
          <div style={{width: "100%", backgroundColor: "red", color: "white", textAlign: "center", height: "30px", justifyContent: "center"}}>
            <a>Bitte Pflichtfelder bearbeiten</a>
          </div>
          :
          <></>
          }
          <div>
            {akademieLetterData.id === null ? <button onClick={e => handleAkademieLetterPush()}>Speichern</button> : <button onClick={() => handleAkademieLetterUpdate()}>Update</button>} 
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('AkademieLetterPreview')}>Vorschau</button>
            <button style={{marginLeft: '20px'}} onClick={e => handleNavpoint('AkademieLetters')}>Abbrechen</button>
          </div>
        </div>
      <div className="Panel">
        <div class="row">
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="contacts" className="smallSpace">Kontakt: </lable>
            </div>
            <div className="upperSpaceLable">
            {complete === false && akademieLetterData.nameSex === "" ? <lable htmlFor="nameSex" style={{color: "red"}} className="smallSpace">Geschlecht: </lable> : <lable htmlFor="nameSex" className="smallSpace">Geschlecht: </lable>}
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="firstName" className="smallSpace">Vorname: </lable>
            </div>
            <div className="upperSpaceLable">
              {complete === false && akademieLetterData.lastName === "" ? <lable htmlFor="lastName" className="smallSpace" style={{color: "red"}}>Name: </lable> : <lable htmlFor="lastname" className="smallSpace">Name: </lable>}
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
            <div className="upperSpace">
                    <select disabled={akademieLetterData.lastName === "" || akademieLetterData.date === "" || akademieLetterData.letterText === "" || akademieLetterData.letterText === " " ? false : true} id="contacts" name="contacts" className="space" style={{width: '80%'}} onChange={handleAkademieLetterChange}>
                      <option value="" disabled selected>Kontakt auswählen</option>
                      {contacts.map(c => (
                        <>
                          <option value={c.id} label={c.businessName !== '' && c.businessName !== null ? c.businessName + ' | ' + c.lastName : c.lastName + ', ' + c.firstName}/>
                        </>
                      ))}
                    </select>
                </div>
              <div className="upperSpace">
                  <select id="nameSex" name="nameSex" className="space" style={{width: '80%'}} onChange={handleAkademieLetterChange} value={akademieLetterData.nameSex}>
                    <option value="" selected disabled>Bitte wählen</option>
                    <option value="female" label='Frau'/>
                    <option value="male" label='Herr'/>
                    <option value="firma" label='Firma'/>
                  </select>
              </div>
              <div className="upperSpace">
                  <input
                    id="firstName"
                    className="space"
                    name="firstName"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.firstName}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="lastName"
                    className="space"
                    name="lastName"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.lastName}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
          </div>
          <div class="column"> {/*Lables Colum 1*/}
            <div className="upperSpaceLable">
              <lable htmlFor="street" className="smallSpace">Straße: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="streetNumber" className="smallSpace">Straßennummer: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="zip" className="smallSpace">Postleitzahl: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="city" className="smallSpace">Stadt: </lable>
            </div>
            <div className="upperSpaceLable">
              <lable htmlFor="country" className="smallSpace">Land: </lable>
            </div>
          </div>
          <div class="column" style={{width: '400px'}}> {/*Inputs Colum 1*/}
          <div className="upperSpace">
                  <input
                    id="street"
                    className="space"
                    name="street"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.street}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="streetNumber"
                    className="space"
                    name="streetNumber"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.streetNumber}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="zip"
                    className="space"
                    name="zip"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.zip}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              <div className="upperSpace">
                  <input
                    id="city"
                    className="space"
                    name="city"
                    type="text"
                    style={{width: '80%'}}
                    value={akademieLetterData.city}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              <select id="country" name="country" className="upperSpace" style={{width: '80%'}} onChange={handleAkademieLetterChange} value={akademieLetterData.country}>
                <option value="" selected disabled>Bitte wählen</option>
                <option value="Deutschland">Deutschland</option>
                {countries.map(c => (
                  <>
                    <option value={c.text}>{c.text}</option>
                  </>
                ))}
              </select>
          </div>
        </div>
      </div>
      <hr/>
      <div className="Panel">
        <div class="row">
          <div class="column">
          
            <div className="upperSpaceLable">
              {complete === false && akademieLetterData.date === "" ? <lable htmlFor="date" className="smallSpace" style={{color: "red", marginLeft: '-440px'}}>Briefdatum: </lable> : <lable htmlFor="date" style={{marginLeft: '-440px'}} className="smallSpace">Datum: </lable>}
            </div>
          </div>
          <div class="column" style={{style: '400px'}}>
            <div className="upperSpace">
                  <input
                    id="date"
                    className="space"
                    name="date"
                    type="date"
                    style={{width: '320px', marginLeft: '-440px'}}
                    value={akademieLetterData.date}
                    onChange={handleAkademieLetterChange}
                  />
              </div>
              
          </div>
        </div>
      </div>
      <hr/>
      <div className='Panel'>
        <div className='row'>
          <div className='column'>
            <div className="upperSpaceLable">
              {complete === false && akademieLetterData.reference === "" ? <lable htmlFor="reference" className="smallSpace" style={{color: "red"}}>Betreff: </lable> : <lable htmlFor="reference" className="smallSpace">Betreff: </lable>}
            </div>
            <div className="upperSpaceLable" >{/*style={{marginTop: '10px'}}*/}
              {complete === false && akademieLetterData.letterText === "" ? <lable htmlFor="letterText" className="smallSpace" style={{color: "red"}}>Brieftext: </lable> : <lable htmlFor="letterText" className="smallSpace">Brieftext: </lable>}
              {/*<button onClick={e => addDiagnoseButton()}>+</button>*/}
            </div>
          </div>
          <div className='column' style={{width: '1110px', marginRight: '-135px'}}>
              <div className="upperSpace">
                    <input
                      id="reference"
                      className="space"
                      name="reference"
                      type="text"
                      style={{width: '80%'}}
                      value={akademieLetterData.reference}
                      onChange={handleAkademieLetterChange}
                    />
                </div>
                <div className="upperSpace">
                    <textarea 
                      id='letterText'
                      name="letterText" 
                      style={{width: '80%', marginBottom: '64px'}} 
                      rows="12" 
                      cols="50" 
                      value={
                        akademieLetterData.letterText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieLetterData.nameSex === 'male' && akademieLetterData.lastName !== '' ? 'Sehr geehrter Herr ' + akademieLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : akademieLetterData.letterText === 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin' && akademieLetterData.nameSex === 'female' && akademieLetterData.lastName !== '' ? 'Sehr geehrte Frau ' + akademieLetterData.lastName + ',' + '\n\n\n\n' + 'Mit freundlichen Grüßen\n\nSimona Michel\nGeschäftsführerin'
                        : akademieLetterData.letterText
                      }
                      onChange={handleAkademieLetterChange}
                    />
                </div>
          </div>
        </div>
      </div>
      </> :<>
        <a>Bei nachträglichen Änderungen werden alle Daten zurückgesetzt</a>
        <button name='change' onClick={handleAkademieLetterChange}>Daten löschen</button>
        <button name='dontChange' onClick={handleAkademieLetterChange}>Abbrechen</button>
      </>
      }
      </>
    )
}

export default newAkademieLetter;









