import { Page, Text, View, Document, StyleSheet, Font, Svg, Line, PDFViewer } from '@react-pdf/renderer';
import MultiSelect from "react-select";
import ReactPDF from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    paddingTop: 60,
    paddingBottom: 130,
    paddingHorizontal: 70
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: "#38b06c"
  },
  author: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 50,
    color: "#38b06c"
  },
  sender: {
    fontSize: 6,
    margin: 1,
    marginLeft: 10,
    color: "#38b06c"
  },
  recipient: {
    marginLeft: 12,
    fontSize: 10,
  },
  invoiceNumber:{
    position: 'absolute',
    fontSize: 6,
    left: 370,
    top: 180
  },
  invoiceNumberValue:{
    position: 'absolute',
    fontSize: 6,
    right: 47,
    top: 180
  },
  invoiceDate:{
    position: 'absolute',
    fontSize: 6,
    left: 370,
    top: 165
  },
  invoiceDateValue:{
    position: 'absolute',
    fontSize: 6,
    right: 47,
    top: 165
  },
  pageNumber:{
    position: 'absolute',
    fontSize: 6,
    left: 370,
    top: 195
  },
  pageNumberValue:{
    position: 'absolute',
    fontSize: 6,
    right: 47,
    top: 195
  },
  invoiceSubject:{
    fontSize: 10,
    margin: 12,
    marginTop: 40,
    textAlign: 'justify'
  },
  invoiceTitle:{
    fontSize: 10,
    marginBottom: 12,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  text: {
    marginLeft: 12,
    marginRight: 12,
    lineHeight: 1.2,
    fontSize: 10,
    textAlign: 'justify',
  },
  smallText: {
    marginLeft: 12,
    marginRight: 12,
    lineHeight: 1.2,
    fontSize: 6,
    textAlign: 'justify',
  },
  tinyText: {
    marginLeft: 12,
    marginRight: 12,
    lineHeight: 1.2,
    fontSize: 6,
    textAlign: 'justify',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    marginTop: 24,
    flexDirection: 'row',
  },
  left: {
    flexBasis: 440,
  },

  right: {
    textAlign: 'right'
  },
  footer: {
    position: 'absolute',
    bottom:25,
    marginTop: 24,
    left: 24,
    flexDirection: 'row',
  },
  footerLeft: {
  },
  footerMid: {
  },

  footerRight: {
    textAlign: 'right',
  },
  pageNumber2: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  viewer: {
    position: 'fixed',
    top: 150,
    right: 0,
    width: window.innerWidth / 1.01,
    height: window.innerHeight / 1.13,
    border: 'none',
  },
});


function InvoicePreview(props) {
    const {
      handleInvoicePush,
      handleInvoiceUpdate,
      invoiceData,
      handleNavpoint
    } = props

    Font.registerHyphenationCallback(word => {
      const middle = Math.floor(word.length / 2);
      const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    
      return parts;
    });

  var sum = 0
  invoiceData.products.forEach((product) => {
    sum += (product.price * invoiceData.quantity)
  })
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  //ReactPDF.render(<Quixote />);
    return(
      <>
        <div className='Panel'>
          <div style={{marginLeft: '-400px'}}>
            <button style={{position: 'absolute', top: '100px', marginLeft: '100px'}} onClick={e => handleNavpoint('NewInvoice')}>Zurück</button>
            {invoiceData.id === null ? <button style={{position: 'absolute', top: '100px'}} onClick={e => handleInvoicePush()}>Speichern</button> : <button style={{position: 'absolute', top: '100px'}} onClick={e => handleInvoiceUpdate()}>Update</button>} 
          </div>
        </div>
      <hr/>
        <PDFViewer style={styles.viewer}>
            <Document>
              <Page style={styles.body}>
                <Text fixed style={styles.title}>PRIVATE MASSAGEPRAXIS</Text>
                <Text fixed style={styles.author}>- SIMONA MICHEL -</Text>
                <Text style={styles.sender}>Simona Michel | Königsteiner Straße 13a | 65812 Bad Soden/Ts.</Text>
                <Text style={styles.recipient}>{' '}</Text>
                <Text style={styles.recipient}>{invoiceData.firstName} {invoiceData.name}</Text>
                <Text style={styles.recipient}>{invoiceData.street} {invoiceData.streetNumber}</Text>
                <Text style={styles.recipient}>{invoiceData.zip} {invoiceData.city}</Text>
                <Text style={styles.invoiceDate}>Rechnungsdatum:</Text>
                <Text style={styles.invoiceDateValue}>{invoiceData.date.substring(8, 10)}.{invoiceData.date.substring(5, 7)}.{invoiceData.date.substring(0,4)}</Text>
                <Text style={styles.invoiceNumber}>Rechnungsnummer:</Text>
                <Text style={styles.invoiceNumberValue}>{invoiceData.invoiceNumber}</Text>
                <Text style={styles.pageNumber}>Seiten:</Text>
                <Text style={styles.pageNumberValue} render={({ pageNumber, totalPages }) => (
                  `${pageNumber} von ${totalPages}`
                )} fixed />
                <Text style={styles.invoiceSubject}>Rechnung</Text>
                <Text style={styles.text}>Sehr geehrte{invoiceData.nameSex === 'male' ? 'r' : ''} {invoiceData.nameSex === 'female' ? 'Frau' : invoiceData.nameSex === 'male' ? 'Herr' : invoiceData.nameSex} {invoiceData.name},</Text>
                <Text style={styles.text}>{'\n'}hiermit erlauben ich mir, folgende Leistung in Rechnung zu stellen:</Text>
                
                <View style={styles.row}>
                  <View style={styles.left}>
                    {invoiceData.products.map((product) => 
                      <Text style={styles.text}>{invoiceData.quantity}x {product.name} {product.short !== '' ? '( '+product.short+' )' : ''} ( {product.number}{product.ending} á {product.price.toFixed(2)} € )</Text>      
                    )}
                  </View>
                  <View style={styles.right}>
                    {invoiceData.products.map((product) => 
                      <Text style={styles.text}>{(invoiceData.quantity * product.price).toFixed(2)} €</Text>      
                    )}
                  </View>
                </View>
                
                <Svg height="5" width="518">
                    <Line x1="2" y1="5" x2="600" y2="5" strokeWidth={2} stroke="rgb(0,0,0)" />
                </Svg>
                
                <View style={[styles.row, {marginTop: '12'}]}>
                  <View style={styles.left}>
          
                      <Text style={styles.text}>Gesamt:</Text>      
          
                  </View>
                  <View style={styles.right}>
                      <Text style={styles.text}>{sum.toFixed(2)} €</Text>      
                  </View>
                </View>
                <Text style={[styles.text, {fontSize: '8'}]}>(Steuerfreie Leistung nach §4 Nr. 14 UStG)</Text>
                
                <Text style={[styles.text, {marginTop: '24'}]}>für Behandlung auf Grund der Verordnung von Dr. {invoiceData.docName} vom {invoiceData.docDate.substring(8, 10)}.{invoiceData.docDate.substring(5, 7)}.{invoiceData.docDate.substring(0,4)} mit der Diagnose: {invoiceData.diagnose.map((d) => d + ', ')}an folgenden Terminen:</Text>
                
                <Text style={styles.text}>{'\n'}{invoiceData.dates.map((date, i) => date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0,4) + ((i % 2) ? '\n' : '       '))}</Text>
                
                <Text style={[styles.text, {marginTop: '36'}]}>{invoiceData.paidText}</Text>
                
                <View fixed style={styles.footer}>
                  <View style={styles.footerLeft}>
                      <Text style={styles.tinyText}>SimLife Institut</Text>
                      <Text style={styles.tinyText}>Königsteiner Straße 13a</Text>
                      <Text style={styles.tinyText}>65812 Bad Soden am Taunus</Text>
                  </View>
                  <View style={styles.footerMid}>
                      <Text style={styles.tinyText}>Telefon: 06196 / 5617022</Text>  
                      <Text style={styles.tinyText}>Website: simlife-institut.com</Text> 
                      <Text style={styles.tinyText}>Email: info@simlife-instiut.com</Text>
                  </View>
                  <View style={styles.footerMid}>
                      <Text style={styles.tinyText}>USt-IdNr: DE255598706</Text>  
                      <Text style={styles.tinyText}>Steuernummer: 46 846 6335 3</Text>
                  </View>
                  <View style={styles.footerRight}>
                      <Text style={styles.tinyText}>Nassauische Sparkasse</Text>  
                      <Text style={styles.tinyText}>IBAN: 35 5105 0015 0197 0491 25</Text>
                      <Text style={styles.tinyText}>BIC: NASSDE55XXX</Text>          
                  </View>
                </View>
              </Page>
            </Document>
        </PDFViewer>
      </>
    )
}

export default InvoicePreview;









