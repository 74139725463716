import "./Navbar.css"
import { useState } from "react"

import logo from '../../Logo.png'

function Navbar(props) {
    const {
        handleNavpoint,
        isAdmin,
        handleSearch,
        searchValue,
        foundContacts,
        foundInvoices,
        foundAkademieInvoices,
        foundManufakturInvoices,
        foundInstitutLetters,
        foundAkademieLetters,
        foundManufakturLetters,
        handleContactDetails,
        handleInvoiceDetails,
        handleAkademieInvoiceDetails,
        handleManufakturInvoiceDetails,
        handleInstitutLetterDetails,
        handleAkademieLetterDetails,
        handleManufakturLetterDetails
    } = props
    const [subNavData, setSubNavData] = useState('')


    return(
        <>
        <ul className="Navbar">
            <li className="MenuPoint" onClick={() => {handleNavpoint("Check"); setSubNavData(subNavData === 'Check' ? '' : 'Check')}}>Anrufe</li>
            <li className="MenuPoint" onClick={() => {handleNavpoint("Book"); setSubNavData(subNavData === 'Book' ? '' : 'Book')}}>Kontakte</li>
            <li className="MenuPoint" onClick={() => setSubNavData(subNavData === 'Institut' ? '' : 'Institut')}>Institut</li>
            <li className="MenuPoint" onClick={() => setSubNavData(subNavData === 'Manufaktur' ? '' : 'Manufaktur')}>Manufaktur</li>
            <li className="MenuPoint" onClick={() => setSubNavData(subNavData === 'Akademie' ? '' : 'Akademie')}>Akademie</li>
            <li className="MenuPoint" onClick={() => handleNavpoint("Links")}>Links</li> 
            {isAdmin === true ? <li className="MenuPoint" onClick={() => handleNavpoint("Admin")}>Admin</li> : <></>}
        </ul>
        {subNavData === 'Institut' ? 
            <ul className="SubNavbar">
                <li className="MenuPoint" style={{color: 'white'}}>Anrufe</li>
                <li className="MenuPoint" style={{color: 'white'}}>Kontakte</li>
                <li className="MenuPoint" onClick={() => {handleNavpoint("Invoices"); setSubNavData(subNavData === 'Institut' ? '' : 'Institut')}}>Rechnung</li> 
                <li className="MenuPoint" onClick={() => {handleNavpoint("InstitutLetters"); setSubNavData(subNavData === 'Institut' ? '' : 'Institut')}}>Korrespondenz</li>
            </ul>
        :
        subNavData === 'Manufaktur' ?
            <ul className="SubNavbar">
                <li className="MenuPoint" style={{color: 'white'}}>Anrufe</li>
                <li className="MenuPoint" style={{color: 'white'}}>Kontakte</li>
                <li className="MenuPoint" style={{color: 'white'}}>Institut</li>
                <li className="MenuPoint" onClick={() => {handleNavpoint("ManufakturInvoices"); setSubNavData(subNavData === 'Manufaktur' ? '' : 'Manufaktur')}}>Rechnung</li> 
                <li className="MenuPoint" onClick={() => {handleNavpoint("ManufakturLetters"); setSubNavData(subNavData === 'Manufaktur' ? '' : 'Manufaktur')}}>Korrespondenz</li>
            </ul>
        :
        subNavData === 'Akademie' ?
            <ul className="SubNavbar">
                <li className="MenuPoint" style={{color: 'white'}}>Anrufe</li>
                <li className="MenuPoint" style={{color: 'white'}}>Kontakte</li>
                <li className="MenuPoint" style={{color: 'white'}}>Institut</li>
                <li className="MenuPoint" style={{color: 'white'}}>Manufaktur</li>
                <li className="MenuPoint" onClick={() => {handleNavpoint("AkademieInvoices"); setSubNavData(subNavData === 'Akademie' ? '' : 'Akademie')}}>Rechnung</li> 
                <li className="MenuPoint" onClick={() => {handleNavpoint("AkademieLetters"); setSubNavData(subNavData === 'Akademie' ? '' : 'Akademie')}}>Korrespondenz</li>
            </ul>
        :
        <></>
        }
        
        <input
            id="search"
            className="SearchBar"
            name="search"
            type="search"
            value={searchValue}
            placeholder="Suche"
            style={{marginBottom: '32px'}}
            onChange={e => handleSearch(e.target.value)}
        />
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundContacts.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Kontakte:</h4>
                    <table className="searchTable">
                        {foundContacts.length > 0 && (
                            foundContacts.sort((a, b) => a.lastName > b.lastName ? 1 : -1).map(contact =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleContactDetails(contact)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.businessName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.lastName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.firstName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.mobile}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.phone}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{contact.email}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <>
                    
                </>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundInvoices.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Institut Invoices:</h4>
                    <table className="searchTable">
                        {foundInvoices.length > 0 && (
                            foundInvoices.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleInvoiceDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.invoiceNumber}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.name}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.sum + ' €'}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundAkademieInvoices.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Akademie Invoices:</h4>
                    <table className="searchTable">
                        {foundAkademieInvoices.length > 0 && (
                            foundAkademieInvoices.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleAkademieInvoiceDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.businessName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.invoiceNumber}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.name}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundManufakturInvoices.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Manufaktur Invoices:</h4>
                    <table className="searchTable">
                        {foundManufakturInvoices.length > 0 && (
                            foundManufakturInvoices.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleManufakturInvoiceDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.invoiceNumber}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.name}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundInstitutLetters.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Institut Letter:</h4>
                    <table className="searchTable">
                        {foundInstitutLetters.length > 0 && (
                            foundInstitutLetters.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleInstitutLetterDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.id}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.lastName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundAkademieLetters.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Akademie Letter:</h4>
                    <table className="searchTable">
                        {foundAkademieLetters.length > 0 && (
                            foundAkademieLetters.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleAkademieLetterDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.id}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.businessName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.lastName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }
        {
            foundContacts.length >= 1 || foundInvoices.length >= 1 || foundAkademieInvoices.length >= 1 || foundManufakturInvoices.length >= 1 || foundInstitutLetters.length >= 1 || foundAkademieLetters.length >= 1 || foundManufakturLetters.length >= 1 ?
            <div className="searchWindow">
                {foundManufakturLetters.length >= 1 ?
                <>
                    <h4 style={{width: "100%", textAlign: "left", fontWeight: "bold", color: "black"}}>Manufaktur Letter:</h4>
                    <table className="searchTable">
                        {foundManufakturLetters.length > 0 && (
                            foundManufakturLetters.map(invoice =>(
                            <>
                                <tr className="clickabale" style={{border: "0px", lineHeight: "20px", height: "20px"}} onClick={() => handleManufakturLetterDetails(invoice)}>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.id}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.date}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.lastName}</th>
                                    <th className="searchResult" style={{border: "0px", lineHeight: "20px", height: "20px"}}>{invoice.firstName}</th>
                                </tr>
                            </>
                            ))
                        )}
                    </table>
                </>
                :
                <></>}
            </div>
            :
            <></>
        }

        <img src={logo} className="App-logo" alt="logo" />
        <p> 06196-5617022 - Königsteinerstraße 13a - 65812 Bad Soden</p>
        </>
    )
}

export default Navbar;