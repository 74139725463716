import "./Login.css"

function Login(props) {
    const {
        setUsername,
        setUserpw,
        handleLogin
    } = props
    return(
        <form className="login">
          <label>
            <p>Username</p>
            <input type="text" onChange={e => setUsername(e.target.value)}/>
          </label>
          <label>
            <p>Password</p>
            <input type="password" onChange={e => setUserpw(e.target.value)}/>
          </label>
          <div>
            <button type="submit" onClick={() => handleLogin()}>Submit</button>
          </div>
        </form>
    )
}

export default Login;